<template>
  <div>
    <v-card class="pa-0" flat width="1200">
      <v-card-title class="justify-center primary--text pb-0 pb-sm-5">
        {{ $t("components.registration_completion.page_title") }}
      </v-card-title>
      <!-- <v-card-text class="text-subtitle-1 py-0">{{
          $t("components.registration_completion.page_subtitle")
        }}</v-card-text> -->
      <v-card-text class="pa-0" v-if="member">
        <v-stepper v-model="step" vertical flat>
          <v-stepper-step
            step="1"
            :complete="step1Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step1_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion_sba.step1_subtitle") }}
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="1"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <registration-completion-form-sba
              :member="member"
              :savingImage="savingImage"
              :dob="dob"
              @dob="dob = $event"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="verify"
                class="mx-2"
                :disabled="!member.FirstName"
              >
                <span v-if="optionalMemberFields?.length > 0">
                  {{
                    $t("components.registration_completion.update_and_continue")
                  }}
                </span>
                <span v-else>
                  {{
                    $t("components.registration_completion.verify_and_continue")
                  }}
                </span>
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="2"
            :complete="step2Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step2_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion_sba.step2_subtitle") }}
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="2"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <contacts-registration-form-sba
              :address="member.Address"
              :phone="member.HomePhone"
              @update-valid="updateValid"
              @update-member="
                contactInfo = $event;
                drawbridgeContactInfoUpdated = true;
              "
              :saving="saving"
              @canada-post-address="updateCanadaPostAddress"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="updateAddress"
                class="mx-2"
                :disabled="!valid.contactsValidation"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="3"
            :complete="step3Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion_sba.step3_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion_sba.step3_subtitle") }}
              <span
                class="custom-underline primary--text"
                @click="chequeExampleDialog = true"
              >
                {{ $t("globals.here") }}.
              </span>
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="3"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <direct-deposit-completion-form
              @update-valid="valid.bankAccount = $event"
              @update-bank-account="bankAccount = $event"
              :memberBankAccount="member.BankAccount"
              :saving="saving"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <!-- <v-btn
                color="primary"
                @click="updateDirectDeposit"
                class="mx-2"
                :disabled="!valid.bankAccount"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn> -->
              <v-btn
                color="primary"
                @click="updateDirectDeposit"
                class="mx-2"
                :disabled="
                  (bankAccountIsEmpty || !valid.bankAccount) &&
                  !bankAccountIsEmpty
                "
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="4"
            :complete="step4Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step4_title") }}
            <!-- <small class="mt-2 black--text">
              {{ $t("components.registration_completion.step4_subtitle") }}
            </small> -->
          </v-stepper-step>

          <v-stepper-content
            step="4"
            :complete="step4Completed"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <profile-picture-form
              :member="member"
              @update-valid="valid.contactsValidation = $event"
              @save-image="newAvatarImage = $event"
              :saving="saving"
            />
            <v-row no-gutters justify="end">
              <v-btn
                color="primary"
                @click="updateProfilePicture"
                class="mx-2"
                :disabled="!valid.contactsValidation"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="5"
            :complete="step === 5"
            class="pl-2 pl-sm-6 pb-0"
          >
            {{ $t("components.registration_completion.step5_title") }}
          </v-stepper-step>

          <v-stepper-content
            step="5"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <div v-if="userProfile?.MemberPlans?.length < 2">
              <v-card-text class="text-subtitle-1 mb-2">
                {{
                  $t("components.member_registration_completed.page_subtitle")
                }}
                <br />
              </v-card-text>
              <v-row no-gutters justify="end">
                <v-btn
                  color="primary"
                  @click="goToDashboard()"
                  class="mx-2"
                  :disabled="!valid.contactsValidation"
                  :loading="saving"
                >
                  {{ $t("globals.close") }}
                </v-btn>
              </v-row>
            </div>
            <!-- Update other plans -->
            <div v-else>
              <v-card-text class="text-subtitle-1">
                {{
                  $t(
                    "components.member_registration_completed.update_all_plans"
                  )
                }}
                <br />
              </v-card-text>
              <v-card-actions class="pa-4 pt-0 pb-1 justify-end">
                <v-btn @click="doNotUpdateOnAllPlans">{{
                  $t("globals.no")
                }}</v-btn>
                <v-btn
                  :loading="updatingPlans"
                  color="primary"
                  class="mr-3"
                  @click="updateMemberPlans"
                  >{{ $t("globals.yes") }}</v-btn
                >
              </v-card-actions>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-container fluid grid-list-md v-else>
        <v-layout row justify-center>
          <v-flex xs12 sm6 md4 lg3>
            <v-progress-circular
              color="info"
              size="40"
              indeterminate
            ></v-progress-circular>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <!-- Confirm cancellation dialog -->
    <v-dialog max-width="800" v-model="cancelDialog" persistent>
      <v-card>
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">mdi-alert-outline</v-icon>
          {{ $t("components.member_registration.cancel_dialog.title") }}
        </v-card-title>
        <v-card-text class="px-5 mb-5 text-subtitle-1">
          <v-row>
            <v-col>
              {{ $t("components.member_registration.cancel_dialog.message") }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0 justify-end">
          <v-btn @click="cancel">{{ $t("globals.cancel") }}</v-btn>
          <v-btn color="primary" class="mr-3" @click="cancelRegistration">
            {{
              $t(
                "components.member_registration.cancel_dialog.exit_registration"
              )
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chequeExampleDialog" persistent max-width="800">
      <v-card max-width="800">
        <v-card-title class="primary white--text text-subtitle-1 mb-6">
          <v-icon dark class="mr-2">mdi-help-circle-outline</v-icon>
          {{
            $t(
              "components.direct_deposit_expansion_panel.cheque_example_dialog.title"
            )
          }}
        </v-card-title>
        <v-card-text>
          <v-img
            v-if="$vuetify.lang.current === 'fr'"
            :src="require('@/assets/ChequeFR.png')"
          ></v-img>
          <v-img v-else :src="require('@/assets/ChequeEN.png')"></v-img>
        </v-card-text>
        <v-card-actions class="pa-5 justify-end pt-0">
          <v-btn color="primary" @click="chequeExampleDialog = false">
            {{ $t("globals.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="confirmBankAccountDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">warning</v-icon>
          {{
            $t(
              "components.direct_deposit_expansion_panel.confirm_bank_account_dialog_title"
            )
          }}
        </v-card-title>
        <v-card-actions>
          <v-btn color text @click="confirmBankAccountDialog = false">
            {{ $t("globals.close") }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="continueBankAccount">
            {{ $t("globals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" :timeout="timeout">
      {{ successMessage }}
      <v-btn dark text @click="snackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="errorSnackbar" color="error" :timeout="-1">
      {{ customErrorMessage
      }}<span
        class="custom-underline secondary--text"
        @click="contactDialog = true"
        >{{ $t("globals.here") }}</span
      >
      <v-btn dark text @click="errorSnackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
    <error-handler :error="error"></error-handler>
  </div>
</template>

<script>
import ErrorHandler from "@/components/ErrorHandler.vue";
import RegistrationCompletionFormSba from "@/components/RegistrationCompletion/RegistrationCompletionFormSba.vue";
import ProfilePictureForm from "@/components/RegistrationCompletion/ProfilePictureForm.vue";
import ContactsRegistrationFormSba from "@/components/RegistrationCompletion/ContactsRegistrationFormSba.vue";
import { mapGetters, mapActions } from "vuex";
import DirectDepositCompletionForm from "@/components/RegistrationCompletion/DirectDepositCompletionForm.vue";
export default {
  components: {
    ErrorHandler,
    RegistrationCompletionFormSba,
    ProfilePictureForm,
    ContactsRegistrationFormSba,
    DirectDepositCompletionForm,
  },
  props: {
    member: Object,
  },
  data() {
    return {
      valid: {
        memberValidation: false,
        bankAccount: false,
        contactsValidation: false,
      },
      savingImage: false,
      cancelDialog: false,
      chequeExampleDialog: false,
      newAvatarImage: null,
      error: null,
      saving: false,
      snackbar: false,
      step: 1,
      successMessage: null,
      timeout: -1,
      step1Completed: false,
      step2Completed: false,
      step3Completed: false,
      step4Completed: false,
      bankAccount: {},
      contactInfo: {},
      address: null,
      dob: undefined,
      confirmBankAccountDialog: false,
      drawbridgeContactInfoUpdated: false,
      drawbridgeAddressUpdated: false,
      bankAccountUpdated: false,
      updateMemberAddress: false,
      customErrorMessage: null,
      errorSnackbar: false,
      updatingPlans: false,
      lastSavedAddress: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", [
      "planAdministrator",
      "userProfile",
      "enumModels",
      "identityUser",
      "optionalMemberFields",
    ]),
    bankAccountIsEmpty() {
      return (
        !this.bankAccount.AccountNumber &&
        !this.bankAccount.InstitutionNumber &&
        !this.bankAccount.TransitNumber
      );
    },
    addressIsEmpty() {
      let result = true;
      if (this.address === null || this.address === undefined) {
        return true;
      }
      Object.values(this.address).forEach((v) => {
        if (v) {
          result = false;
        }
      });
      return result;
    },
  },
  watch: {
    member: {
      handler(v) {
        if (v) {
          this.dob = v.Dob.split("T")[0];
          this.address = v?.Address;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions("auth", ["logout", "setUser", "setIsLoggedIn"]),
    ...mapActions("users", ["updateUser"]),
    ...mapActions("common", ["loadUserProfileData"]),
    ...mapActions("data", [
      "checkValidMember",
      "checkValidEmail",
      "verifyMember",
      "validateBankAccount",
      "updateMemberInfoOnAllPlans",
    ]),
    ...mapActions("common", ["clearUserProfileData"]),
    async verify() {
      this.step1Completed = true;
      this.step = await this.checkStepsCompletion();
    },
    updateValid(event) {
      // console.log("update valid", event);
      this.valid.contactsValidation = event;
    },
    async updateAddress() {
      this.step2Completed = true;
      this.step = await this.checkStepsCompletion();
    },
    updateCanadaPostAddress(address) {
      console.log("canada post address update", address);
      this.address = address;
      this.lastSavedAddress = address;
      // this.valid.contactsValidation = true;
      this.drawbridgeContactInfoUpdated = true;
      this.drawbridgeAddressUpdated = true;
    },
    async updateDirectDeposit() {
      if (!this.bankAccountIsEmpty) {
        // if (this.address === "ddd") {
        try {
          this.validating = true;
          const valid = (
            await this.validateBankAccount({
              payload: {
                InstitutionNumber: this.bankAccount.InstitutionNumber,
                TransitNumber: this.bankAccount.TransitNumber,
              },
            })
          ).data;
          this.bankAccountUpdated = true;
          if (!valid) {
            this.confirmBankAccountDialog = true;
          } else {
            this.step3Completed = true;
            this.step = await this.checkStepsCompletion();
          }
        } catch (error) {
          this.error = error;
        } finally {
          this.validating = false;
        }
      } else {
        this.step3Completed = true;
        this.step = await this.checkStepsCompletion();
      }
    },

    async continueBankAccount() {
      this.confirmBankAccountDialog = false;
      this.step3Completed = true;
      this.step = await this.checkStepsCompletion();
    },

    async updateMemberPlans() {
      this.updatingPlans = true;
      this.errorSnackbar = false;
      this.snackbar = false;
      // console.log("update all plans address", JSON.stringify(this.address));
      this.successMessage = this.$t(
        "components.registration_completion.update_plans.success"
      );
      if (!this.address.Category) {
        if (this.address.Country) {
          this.address.Category = "Civic";
        } else if (
          this.address.OtherCountry?.toUpperCase() === "UNITED STATES"
        ) {
          this.address.Category = "Non-Civic US";
        } else this.address.Category = "Non-Civic International";
      }
      if (this.lastSavedAddress) this.address = this.lastSavedAddress;
      if (this.address.Category === "Civic") {
        const province = this.enumModels?.Province?.find(
          (p) => p.value?.toUpperCase() === this.address.Province?.toUpperCase()
        );
        this.address.Province = province?.textEn;
        this.address.ProvinceCode = province?.code;
        this.address.ProvinceId = province?.value;
        this.address.ProvinceName = province?.textEn;
        this.address.Country = this.enumModels?.Country?.find(
          (c) => c.value?.toUpperCase() === this.address.Country?.toUpperCase()
        )?.textEn;
      } else {
        this.address.Province = null;
        this.address.Country = null;
      }
      // console.log("contry is", this.address);
      try {
        await this.updateMemberInfoOnAllPlans({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: {
            memberProfile: {
              Address: this.address,
              BankAccount: this.bankAccount,
              EmailAddress: this.member.EmailAddress,
              SecondaryEmail: this.member.SecondaryEmail,
              HomePhone: this.contactInfo.HomePhone,
              MobilePhone: this.member.MobilePhone,
              Communication: this.member.Communication,
              Language: this.member.Language,
              PreferredPhone: this.contactInfo.PreferredPhone,
            },
            memberPlans: this.member.MemberPlans,
            updateFlags: {
              bankAccountUpdated: this.bankAccountUpdated,
              drawbridgeContactInfoUpdated: this.drawbridgeContactInfoUpdated,
              drawbridgeAddressUpdated: this.drawbridgeAddressUpdated,
            },
          },
        });
        await this.loadUserProfileData({
          planId: this.user.plan,
          userId: this.user.sub,
        });
        this.snackbar = true;
        this.link = null;
        this.linkText = null;
        this.goToDashboard();
        this.drawbridgeContactInfoUpdated = false;
        this.drawbridgeAddressUpdated = false;
        this.bankAccountUpdated = false;
      } catch (error) {
        console.log(error);
        this.customErrorMessage = this.$t(
          "components.registration_completion.update_plans.error"
        );
        this.step = 5;
        this.errorSnackbar = true;
        await this.updateUser({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: {
            ...this.user,
            isRegistrationCompleted: false,
          },
        });
      } finally {
        this.updatingPlans = false;
      }
    },

    async doNotUpdateOnAllPlans() {
      await this.updateUser({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.sub,
        payload: {
          ...this.user,
          isRegistrationCompleted: true,
        },
      });
      this.goToDashboard();
    },

    cancel() {
      this.cancelDialog = false;
    },

    async updateProfilePicture() {
      this.step4Completed = true;
      this.step = await this.checkStepsCompletion();
    },

    async checkStepsCompletion() {
      if (!this.step1Completed) {
        this.error = {
          customMessage: this.$t(
            "components.registration_completion.update_contacts.error_other_steps"
          ),
        };
        return 1;
      }
      if (!this.step2Completed) {
        if (this.step !== 1) {
          this.error = {
            customMessage: this.$t(
              "components.registration_completion.update_contacts.error_other_steps"
            ),
          };
        }
        return 2;
      }
      if (!this.step3Completed) {
        if (this.step !== 2) {
          this.error = {
            customMessage: this.$t(
              "components.registration_completion.update_contacts.error_other_steps"
            ),
          };
        }
        return 3;
      }
      if (!this.step4Completed) {
        return 4;
      }
      try {
        this.saving = true;
        await this.saveUser(true);
        return 5;
      } catch (error) {
        console.log(error);
        this.error = {
          customMessage: this.$t(
            "components.registration_completion.error.unable_to_complete"
          ),
        };
        // this.error = error;
        return 4;
      } finally {
        this.saving = false;
      }
    },

    cancelRegistration() {
      this.clearUserProfileData();
      this.logout();
      this.setUser(undefined);
      this.setIsLoggedIn(false);
      this.$router.push({ name: "login" });
    },

    goToDashboard() {
      this.$emit("close-dialog");
      this.$router.push("/");
    },

    async saveUser(isRegistrationCompleted) {
      this.successMessage = this.$t(
        "components.registration_completion.save_member.success"
      );
      this.contactInfo.Address = this.addressIsEmpty
        ? null
        : this.lastSavedAddress;
      await this.verifyMember({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.drawbridgeMemberId,
        payload: {
          ContactInfo: this.contactInfo,
          BankAccount: this.bankAccountIsEmpty ? null : this.bankAccount,
          DateOfBirth: this.dob,
        },
      });

      await this.updateUser({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.sub,
        payload: {
          ...this.user,
          image: this.newAvatarImage,
          isRegistrationCompleted: isRegistrationCompleted,
        },
      });
      this.snackbar = true;
      this.loadUserProfileData({
        planId: this.user.plan,
        userId: this.user.sub,
      });
    },
  },
};
</script>
