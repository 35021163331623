/* eslint-disable prettier/prettier */
import moment from "moment";
import store from "@/store";
import { i18n } from "@/plugins/i18n";
import states from "@/statesUSA";
import streetDirections from "@/streetDirections";
import streetTypesMostCommon from "@/streetTypesMostCommon";
import streetTypes from "@/streetTypesNeospin";
import canadaPostStreetTypes from "@/canadaPostStreetTypes";
import canadaPostStreetTypesFR from "@/canadaPostStreetTypesFR";

const helperMethods = {
  formattedDateNow: () => {
    let day = new Date().toLocaleDateString("en-us", { day: "numeric" });
    let month = new Date().toLocaleDateString("en-us", { month: "numeric" });
    let year = new Date().toLocaleDateString("en-us", { year: "numeric" });
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
    return formattedDate;
  },
  dateNow: () => {
    return moment().toISOString();
  },
  dateRangesMonths: (months) => {
    if (months <= 0) return;
    if (months >= 1) {
      const startDate = moment().subtract(months, "months");
      return [startDate.format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    }
    if (months === 0.5) {
      const startDate = moment().subtract(14, "days");
      return [startDate.format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    }
  },
  datesLastMonthsOnly: () => {
    const startDate = moment().subtract(1, "month").startOf("month");
    const endDate = moment().subtract(1, "month").endOf("month");
    return [startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD")];
  },
  dateRangesForYear: (year) => {
    const startDateOfTheYear = moment([year]);
    // console.log(
    //   "start day of year",
    //   startDateOfTheYear.format("YYYY-MM-DD"),
    //   moment([year]).endOf("year").format("YYYY-MM-DD"),
    //   year
    // );
    if (year === moment().year()) {
      return [
        startDateOfTheYear.format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
    } else {
      return [
        startDateOfTheYear.format("YYYY-MM-DD"),
        moment([year]).endOf("year").format("YYYY-MM-DD"),
      ];
    }
  },
  formatDate(date) {
    if (!date) return "";
    // console.log("date to convert", date);
    if (date.includes("0001-01-01")) return "";
    if (date) {
      const locale = i18n.locale;
      moment.locale(locale);
      const convertedDate = moment(date, [
        "YYYY/MM/DD",
        "YYYY-MM-DD",
        "DD/MM/YYYY",
        "DD-MM-YYYY",
        "MMMM DD, YYYY",
      ]).format("MMM-DD-YYYY");
      const result = this.capitalize(convertedDate.replace(".", ""));
      // console.log("date result", date);
      return result;
    } else return "";
  },
  formatDateYYYYMMDD(date) {
    if (!date) return "";
    // console.log("date to convert", date);
    if (date.includes("0001-01-01")) return "";
    if (date) {
      const locale = i18n.locale;
      moment.locale(locale);
      const convertedDate = moment(date, [
        "YYYY/MM/DD",
        "YYYY-MM-DD",
        "DD/MM/YYYY",
        "DD-MM-YYYY",
        "MMMM DD, YYYY",
      ]).format("YYYY-MM-DD");
      return convertedDate;
    } else return "";
  },
  numberWithCommas(x) {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  numberWithCommasAndDecimalPoint(x) {
    x = parseFloat(x).toFixed(2);
    var parts = x.toString().split(".");
    parts[0] = parseInt(parts[0]).toLocaleString("en-US");
    return parts.join(".");
  },
  fileExtension(name) {
    if (!name) return;
    const arr = name.split(".");
    if (arr.length) {
      const ext = arr[arr.length - 1];
      return ext.length > 0 ? ext : "";
    }
    return "";
  },
  toCurrency(number) {
    if (typeof number !== "number") return "";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  },
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return null;
  },
  capitalize(str) {
    if (!str) return;
    const arr = str.split(" ");
    const capitalized = arr.map(
      (word) =>
        word.substring(0, 1).toUpperCase() +
        word.substring(1, word.length).toLowerCase()
    );
    return capitalized.join(" ");
  },
  toISOStringLocal(d) {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    return (
      d.getFullYear() +
      "-" +
      z(d.getMonth() + 1) +
      "-" +
      z(d.getDate()) +
      "T" +
      z(d.getHours()) +
      ":" +
      z(d.getMinutes()) +
      ":" +
      z(d.getSeconds())
    );
  },
  formatDrawbridgeAddress(address) {
    var res = "";
    if (address) {
      if (address.Address1) {
        res += address.Address1 + "\n";
        if (address.Address2) {
          res += address.Address2 + "\n";
          if (address.Address3) {
            res += address.Address3 + "\n";
          }
        }
      } else {
        if (address.Country) {
          const enumModels = store.getters["common/enumModels"];
          const enumCountry = enumModels.Country.find(
            (o) => o.value.toUpperCase() === address.Country.toUpperCase()
          );
          if (enumCountry?.isOther) {
            if (address.SuiteNumber) res += address.SuiteNumber;
            if (address.StreetNumber) {
              if (res.length > 0) res += "-";
              res += address.StreetNumber.toString();
            }
            if (address.StreetName) {
              if (res.length > 0) res += " ";
              res += address.StreetName;
            }
            if (address.City) {
              if (res.length > 0) res += "\n";
              res += address.City;
            }
            if (address.OtherProvince) {
              if (res.length > 0) {
                res += ", ";
              }
              if (address.OtherCountry?.toUpperCase() === "UNITED STATES") {
                const stateCode = states.find(
                  (s) => s.name === address.OtherProvince
                );
                if (res.length > 0) res += " ";
                res += stateCode?.code || "";
              } else {
                res += address.OtherProvince;
              }
            }
            if (address.OtherPostalCode) {
              res += " " + address.OtherPostalCode;
            }
            if (address.OtherCountry) {
              res += "\n " + address.OtherCountry;
            }
          } else {
            if (address.SuiteNumber) res += address.SuiteNumber;
            if (address.StreetNumber) {
              if (res.length > 0) res += "-";
              res += address.StreetNumber.toString();
            }
            if (address.StreetName) {
              if (res.length > 0) res += " ";
              res += address.StreetName;
            }
            if (address.City) {
              if (res.length > 0) res += "\n";
              res += address.City;
            }
            if (address.Province) {
              if (res.length > 0) res += ", ";
              if (address.Province.Code) {
                res += address.Province.Code;
              } else {
                res += this.formatDrawbridgeProvinceCode(
                  address.Province,
                  "Province"
                );
              }
            }

            if (address.PostalCode) res += " " + address.PostalCode;
            if (address.Country) {
              let country =
                this.formatEnumModel(address.Country, "Country") ||
                address.Country;
              if (country.toUpperCase() !== "CANADA")
                country = address?.OtherCountry;
              res += "\n " + country;
            }
          }
        }
      }
    }
    return res;
  },

  formatCcwippAddress(address, lang) {
    var res = "";
    if (!address) return res;

    if (address.AddressLine) {
      res += address.AddressLine;
      if (address.AddressLine2) {
        res += "\n" + address.AddressLine2;
        if (address.AddressLine3) {
          res += "\n" + address.AddressLine3;
        }
      }
      if (address.State && address.City) {
        if (
          !address.AddressLine?.toUpperCase().includes(
            address.City?.toUpperCase()
          ) &&
          !address.AddressLine2?.toUpperCase().includes(
            address.City?.toUpperCase()
          ) &&
          !address.AddressLine3?.toUpperCase().includes(
            address.City?.toUpperCase()
          )
        ) {
          if (res.length > 0) res += "\n";
          res += address.City;
        }
      }
      // if (address.AddressLine3 && address.Province) {
      //   if (res.length > 0) res += " ";
      //   res += address.Province;
      // }
      if (address.State) {
        const stateCode =
          states.find((s) => s.name === address.State)?.code || "";
        if (
          !address.AddressLine?.toUpperCase().includes(stateCode) &&
          !address.AddressLine2?.toUpperCase().includes(stateCode) &&
          !address.AddressLine3?.toUpperCase().includes(stateCode)
        ) {
          if (res.length > 0) res += " ";
          res += stateCode;
        }
      }
      if (address.ZipCode) {
        const fullZipCode =
          address.ZipCode + (address.Zip4Code ? "-" + address.Zip4Code : "");
        if (
          !address.AddressLine?.includes(fullZipCode) &&
          !address.AddressLine2?.includes(fullZipCode) &&
          !address.AddressLine3?.includes(fullZipCode)
        ) {
          if (res.length > 0) res += " ";
          res += fullZipCode;
        }
      }

      //if (address.PostalCode) res += " " + address.PostalCode;
    } else {
      if (address.AptUnitSuite) res += address.AptUnitSuite;
      if (address.StreetNumber) {
        if (res.length > 0) res += "-";
        res += address.StreetNumber.toString();
      }
      if (address.StreetName) {
        if (res.length > 0) res += " ";
        res += address.StreetName;
        if (address.StreetType) {
          if (res.length > 0) res += " ";
          if (
            typeof address.StreetType === "object" &&
            address.StreetType !== null
          ) {
            res +=
              lang === "EN"
                ? this.capitalize(address.StreetType.textEn)
                : this.capitalize(address.StreetType.textFr);
          } else {
            res += address.StreetType;
          }
        }
        if (address.StreetDirection) {
          if (res.length > 0) res += " ";
          res += address.StreetDirection;
        }
      }
      if (address.City) {
        if (res.length > 0) res += "\n";
        res += address.City;
      }
      if (address.Province) {
        if (res.length > 0) res += ", ";
        res += address.Province;
      }

      if (address.PostalCode) res += " " + address.PostalCode;
    }
    if (address.Country && !res.includes(address.Country)) {
      res += "\n" + address.Country;
    }
    //console.log("format ccwipp address", res);
    return res;
  },

  cleanUpStreetName(street) {
    console.log("street name to clean up", street);
    const { streetNoDirections, streetDirection } =
      this.cleanupDirectionFromStreetString(street, streetDirections);
    const streetNoCommonStreetTypes = this.cleanupStreetString(
      streetNoDirections,
      streetTypesMostCommon
    );
    const streetNoStreetTypes = this.cleanupStreetString(
      streetNoCommonStreetTypes,
      streetTypes
    );
    console.log("the street direction is", streetDirection);
    return { streetName: streetNoStreetTypes, streetDirection };
  },

  cleanupStreetString(street, list) {
    if (street.split(" ")?.length < 2) return street;
    let streetWords = street.split(" ");
    let indexes = [];
    streetWords.forEach((w, i) => {
      const matchKey = w.toUpperCase();
      if (list[matchKey]) {
        indexes.push(i);
      }
    });
    let wordsCount = streetWords.length;
    if (indexes.length) {
      for (let i = 0; i < indexes.length; i++) {
        if (i < wordsCount - 1) {
          streetWords[indexes[i]] = " ";
        }
      }
    }
    let streetResult = streetWords.join(" ").replaceAll("  ", " ").trim();
    // console.log("cleaned up street name", streetResult);
    return streetResult;
  },

  cleanupDirectionFromStreetString(street, list) {
    if (street.split(" ")?.length < 2) return street;
    let streetDirection = "";
    let streetWords = street.split(" ");
    let indexes = [];
    streetWords.forEach((w, i) => {
      const matchKey = w.toUpperCase();
      if (list[matchKey]) {
        indexes.push(i);
        streetDirection = list[matchKey];
      }
    });
    let wordsCount = streetWords.length;
    if (indexes.length) {
      for (let i = 0; i < indexes.length; i++) {
        if (i < wordsCount - 1) {
          streetWords[indexes[i]] = " ";
        }
      }
    }
    let streetNoDirections = streetWords.join(" ").replaceAll("  ", " ").trim();
    // console.log("cleaned up street name", streetResult);
    return { streetNoDirections, streetDirection };
  },

  parseStreetCanada(street, streetTypes = canadaPostStreetTypes) {
    let streetName;
    let streetType;
    const streetTypesRegExStr = streetTypes
      .map(
        (o) =>
          `^${o.abbreviation.toUpperCase()} | ${o.abbreviation.toUpperCase()}$| ${o.abbreviation.toUpperCase()} `
      )
      .join("|");
    // console.log(streetTypesRegExStr);
    const streetTypesRegEx = new RegExp(`${streetTypesRegExStr}`, "g");
    const streetX = street.replaceAll(" ", "  ");
    const streetMatches = streetX.toUpperCase().matchAll(streetTypesRegEx);
    let streets = [];
    for (const match of streetMatches) {
      //console.log("match found", match, streetX);
      streetName = streetX
        .replace(
          `${streetX.substring(match.index, match.index + match[0].length)}`,
          ""
        )
        .replace("  ", " ")
        .trim();
      streetType = match[0].trim();
      streets.push({ streetName, streetType });
    }
    if (
      streets.length === 0 &&
      street !== null &&
      street !== undefined &&
      street !== ""
    ) {
      console.log("no match found");
      streets.push({
        streetName: street,
      });
    }
    return streets;
  },

  parseStreetByCountryAndLanguage(address, selectedCountry) {
    if (selectedCountry.isOther) {
      return {
        streetType: address.Street,
      };
    } else {
      if (selectedCountry.code === "CAN") {
        if (address.Language.toUpperCase() === "ENG") {
          return this.parseStreetCanada(address.Street, canadaPostStreetTypes);
        } else if (address.Language.toUpperCase() === "FRE") {
          return this.parseStreetCanada(
            address.Street,
            canadaPostStreetTypesFR
          );
        }
      } else if (selectedCountry.code === "USA") {
        // return this.parseStreetUs(street);
        return {
          streetName: address.Street,
        };
      }
    }
  },

  translate(item, language) {
    if (!item) return "";
    //console.log(item, item.Translations)
    if (item.Translations) {
      let trans = item.Translations.filter((o) => o.LanguageCode === language);
      //console.log(trans)
      return trans.length > 0
        ? trans[0].DisplayValue
        : language + "-" + item.Name;
    }
    return item.Name;
  },
  translate2(item, field, language) {
    // console.log(item, field, language)
    if (item === null || item === undefined) return "";
    if (item.Translations === null || item.Translations === undefined)
      return item[field] || "";
    const trans = item.Translations.filter((o) => o.LanguageCode === language);
    if (trans.length > 0)
      if (Object.keys(trans[0]).indexOf(field) > -1)
        return trans[0][field] || item[field] || "";
    return item[field] || "";
  },
  wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  formatEnumModel(item, field, lang) {
    const enumModels = store.getters["common/enumModels"];
    const enumValue = enumModels[field].filter(
      (o) => o.value.toUpperCase() === item.toUpperCase()
    );
    if (enumValue[0]) {
      if (lang === "en") {
        return enumValue[0].textEn;
      }
      return enumValue[0].textFr;
    }
    return "";
  },
  formatDrawbridgeProvinceCode(item, field) {
    const enumModels = store.getters["common/enumModels"];
    const enumValue = enumModels[field].filter(
      (o) => o.value.toUpperCase() === item.toUpperCase()
    );
    if (enumValue[0]) {
      return enumValue[0].code;
    }
    return "";
  },
  replaceFrenchChars(inputStr) {
    return inputStr
      .replaceAll("à", "a")
      .replaceAll("â", "a")
      .replaceAll("ä", "a")
      .replaceAll("è", "e")
      .replaceAll("é", "e")
      .replaceAll("ê", "e")
      .replaceAll("ë", "e")
      .replaceAll("î", "i")
      .replaceAll("ï", "i")
      .replaceAll("ô", "o")
      .replaceAll("ù", "u")
      .replaceAll("û", "u")
      .replaceAll("ü", "u")
      .replaceAll("ÿ", "y")
      .replaceAll("ç", "c")
      .replaceAll("À", "A")
      .replaceAll("Â", "A")
      .replaceAll("Ä", "A")
      .replaceAll("È", "E")
      .replaceAll("É", "E")
      .replaceAll("Ê", "E")
      .replaceAll("Ë", "E")
      .replaceAll("Î", "I")
      .replaceAll("Ï", "I")
      .replaceAll("Ô", "O")
      .replaceAll("Ù", "U")
      .replaceAll("Û", "U")
      .replaceAll("Ü", "U")
      .replaceAll("Ÿ", "Y")
      .replaceAll("Ç", "C");
    //(œ Œ)
  },
};

const helpers = {
  install(Vue) {
    Vue.prototype.$helpers = helperMethods;
  },
};

export default helpers;
