<template>
  <div>
    <material-card-sba
      :size.sync="internalSize"
      :order.sync="internalOrder"
      :height.sync="internalHeight"
      :maxOrderValue="maxOrderValue"
      :isBenefitCardsWidget="true"
      :color="noTitle ? '' : 'info'"
      :widgetIcon="widgetIcon"
      :title="
        noTitle
          ? ' '
          : $t('components.vue_material_dashboard.items.benefit_cards.title')
      "
    >
      <v-row no-gutters class="ma-2 ma-sm-4">
        <v-col cols="12" md="7" class="pr-0 pr-sm-4">
          <v-row no-gutters justify="center" style="height: 200px">
            <div v-if="loading">
              <div class="mt-n1 mt-sm-n4 text-subtitle-2">
                {{ $t("components.benefit_cards.loading_cards") }}
              </div>
              <v-skeleton-loader
                height="200"
                width="320"
                type="article, list-item-two-line"
              ></v-skeleton-loader>
            </div>
            <div v-else-if="!hasCards">
              <p class="mt-12">
                {{ $t("components.benefit_cards.no_cards_found") }}
              </p>
            </div>

            <v-carousel
              v-model="types"
              height="187"
              hide-delimiters
              :show-arrows="enableAll"
              v-if="hasCards"
            >
              <div v-for="card in benefitCards" :key="card.Id">
                <v-carousel-item :value="card.Type" v-if="card.IsEligible">
                  <v-sheet
                    height="100%"
                    tile
                    @click="positionBottom = !positionBottom"
                  >
                    <v-row class="fill-height" justify="center" no-gutters>
                      <benefit-card
                        :card="card"
                        :position="positionBottom"
                        :cardId="card.Id"
                      />
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </div>
            </v-carousel>
          </v-row>
          <v-row no-gutters class="mx-4 mt-n2" justify="space-around">
            <v-btn-toggle v-model="types" tile color="accent" group>
              <v-btn
                v-if="hasDrugCard"
                value="drug"
                width="70"
                height="70"
                text
                :disabled="!hasDrugCard"
                @click="enableAll = false"
              >
                <v-row class="flex-column">
                  <v-icon>mdi-medication-outline</v-icon>
                  <span class="btn-text">{{
                    $t(
                      `components.vue_material_dashboard.items.benefit_cards.pharmacy_card`
                    )
                  }}</span>
                </v-row>
              </v-btn>

              <v-btn
                value="health-dental"
                @click="enableAll = false"
                width="70"
                height="70"
                text
                v-if="hasHealthDentalCard"
                :disabled="!hasHealthDentalCard"
              >
                <v-row class="flex-column">
                  <v-icon>mdi-tooth-outline</v-icon>
                  <span class="btn-text">{{
                    $t(
                      `components.vue_material_dashboard.items.benefit_cards.health_dental_card`
                    )
                  }}</span>
                </v-row>
              </v-btn>

              <v-btn
                value="health"
                @click="enableAll = false"
                width="70"
                height="70"
                text
                v-if="hasHealthCard"
                :disabled="!hasHealthCard"
              >
                <v-row class="flex-column">
                  <v-icon>mdi-medical-bag</v-icon>
                  <span class="btn-text">{{
                    $t(
                      `components.vue_material_dashboard.items.benefit_cards.health_card`
                    )
                  }}</span>
                </v-row>
              </v-btn>

              <v-btn
                value="dental"
                @click="enableAll = false"
                width="70"
                height="70"
                text
                v-if="hasDentalCard"
                :disabled="!hasDentalCard"
              >
                <v-row class="flex-column">
                  <v-icon>mdi-tooth-outline</v-icon>
                  <span class="btn-text">{{
                    $t(
                      `components.vue_material_dashboard.items.benefit_cards.dental_card`
                    )
                  }}</span>
                </v-row>
              </v-btn>

              <v-btn
                value="medical"
                width="70"
                height="70"
                text
                v-if="hasMedicalCard"
                :disabled="!hasMedicalCard"
                @click="enableAll = false"
              >
                <v-row class="flex-column">
                  <v-icon>mdi-medical-bag</v-icon>
                  <span class="btn-text">{{
                    $t(
                      `components.vue_material_dashboard.items.benefit_cards.medical_card`
                    )
                  }}</span>
                </v-row>
              </v-btn>

              <v-btn
                value="travel"
                width="70"
                height="70"
                text
                v-if="hasTravelCard"
                :disabled="!hasTravelCard"
                @click="enableAll = false"
              >
                <v-row class="flex-column">
                  <v-icon>mdi-airplane</v-icon>
                  <span class="btn-text">{{
                    $t(
                      `components.vue_material_dashboard.items.benefit_cards.travel_card`
                    )
                  }}</span>
                </v-row>
              </v-btn>
            </v-btn-toggle>
          </v-row>
          <v-btn
            block
            value="all"
            @click="enableAll = true"
            text
            v-if="hasCards"
            :disabled="!hasCards"
            color="info"
            class="all-button"
            ><span class="all-btn">{{
              $t(
                "components.vue_material_dashboard.items.benefit_cards.view_all"
              )
            }}</span>
          </v-btn>
          <v-row no-gutters v-if="hasCards">
            <v-col cols="12" class="bottom-note">
              {{
                $t(
                  "components.vue_material_dashboard.items.benefit_cards.choose_note"
                )
              }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="5">
          <v-row justify="center" no-gutters>
            <div style="max-width: 270px">
              <v-btn
                :disabled="!hasCards"
                large
                outlined
                block
                @click="print"
                color="info"
                class="rounded-lg mb-3 action-btn"
              >
                <v-icon style="font-size: 25px !important" class="pr-2"
                  >mdi-printer-outline
                </v-icon>
                <span class="secondary--text action-btn-text">{{
                  $t(
                    "components.vue_material_dashboard.items.benefit_cards.print"
                  )
                }}</span></v-btn
              >
              <v-btn
                :disabled="!hasCards"
                large
                outlined
                block
                @click="download"
                color="info"
                class="rounded-lg mb-3 action-btn"
              >
                <v-icon style="font-size: 25px !important" class="pr-2"
                  >mdi-download
                </v-icon>
                <span class="secondary--text action-btn-text">{{
                  $t(
                    "components.vue_material_dashboard.items.benefit_cards.download"
                  )
                }}</span></v-btn
              >
              <v-btn
                :disabled="!hasCards"
                large
                outlined
                block
                @click="sendCardByEmail"
                :loading="sending"
                color="info"
                class="rounded-lg mb-4 action-btn"
              >
                <v-icon style="font-size: 25px !important" class="pr-2"
                  >mdi-share-variant
                </v-icon>
                <span class="secondary--text action-btn-text">{{
                  $t("globals.email")
                }}</span></v-btn
              >

              <v-row no-gutters align="center">
                <v-col class="pa-0" cols="auto">
                  <v-icon
                    size="40"
                    color="secondary"
                    class="float-left d-block mr-2"
                    >mdi-alert-circle-outline
                  </v-icon>
                </v-col>
                <v-col class="text-left font-weight-medium note mt-2">
                  {{
                    $t(
                      `components.vue_material_dashboard.items.benefit_cards.${
                        types ? types : "default"
                      }`
                    )
                  }}
                </v-col>
              </v-row>
              <div
                v-if="types && types !== 'travel'"
                class="text-left font-weight-medium mt-2 note pb-2"
              >
                {{
                  $t(
                    "components.vue_material_dashboard.items.benefit_cards.note2"
                  )
                }}
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </material-card-sba>
    <div id="element-to-download" class="d-none">
      <div
        style="text-align: center"
        v-for="(card, i) in cardsToDownload"
        :key="card.Id"
      >
        <img
          width="400"
          :src="card.Source"
          :class="
            cardsToDownload.length > 1 && i + 1 < cardsToDownload.length
              ? 'html2pdf__page-break'
              : ''
          "
        />
      </div>
    </div>

    <error-handler :error="error"></error-handler>

    <v-snackbar v-model="snackbar" color="success" :timeout="3000">
      {{ $t("components.vue_material_dashboard.items.benefit_cards.success") }}
      <v-btn dark text @click="snackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
import BenefitCard from "@/components/BenefitCard/BenefitCard.vue";
// import mockBenefitCards from "./mockBenefitCards.json";
import { saveAs } from "file-saver";
import { printFileFromBase64 } from "@/printFileFromBase64";
import ErrorHandler from "@/components/ErrorHandler.vue";
export default {
  components: {
    MaterialCardSba,
    BenefitCard,
    ErrorHandler,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
    noTitle: Boolean,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      benefitCards: null,
      loading: false,
      hasMedicalCard: false,
      hasTravelCard: false,
      hasDrugCard: false,
      hasHealthDentalCard: false,
      hasHealthCard: false,
      hasDentalCard: false,
      hasCards: false,
      types: null,
      positionBottom: false,
      enableAll: false,
      cardsToDownload: [],
      sending: false,
      snackbar: false,
      error: undefined,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["userProfile"]),
    coverages() {
      const resp = [];
      if (this.userProfile && this.userProfile.Coverages) {
        for (const coverage of this.userProfile.Coverages) {
          let str = this.$helpers.translate2(
            coverage.CurrentBenefit,
            "Name",
            this.$vuetify.lang.current
          );
          if (coverage.CoverageStatus) {
            str += ` - ${this.$helpers.translate(
              coverage.CoverageStatus,
              this.$vuetify.lang.current
            )}`;
            if (
              coverage.CoverageStatus.Name == "Family" ||
              coverage.CoverageStatus.Name == "Single Parent"
            ) {
              if (
                coverage.NoOfDependants !== null &&
                coverage.NoOfDependants !== undefined
              ) {
                str += ` (${coverage.NoOfDependants})`;
              }
            }
          }
          if (coverage.Volume) {
            str += ` (${coverage.Volume})`;
          }

          resp.push(str.toLowerCase());
        }
      }
      return resp.join(", ");
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
    benefitCards: {
      handler(v) {
        if (v) {
          this.checkCardTypes();
          // console.log("cards checked", this.hasCards, v);
        }
      },
      immediate: true,
      deep: true,
    },
    coverages: {
      handler(v) {
        if (v) {
          this.checkCardTypes();
          // console.log("coverages: cards checked", this.hasCards, v);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getBenefitCards", "sendBenefitCardByEmail"]),
    // download cards as jpg
    download() {
      this.cardsToDownload = [];
      let filename = "";
      if (this.enableAll) {
        this.cardsToDownload = this.benefitCards?.filter((c) => c.IsEligible);
        this.cardsToDownload.forEach(function (c) {
          fetch(c.Source)
            .then((res) => res.blob())
            .then((blob) => {
              saveAs(blob, c.Type + "-card.jpg");
            });
        });
      } else {
        const card = this.benefitCards?.find((c) => c.Type === this.types);
        this.cardsToDownload.push(card);
        filename = card.Type + "-card.jpg";
        const blob = this.b64toBlob(card.Source.split(",")[1]);
        saveAs(blob, filename);
      }

      // html2pdf(document.getElementById("element-to-download"), {
      //   margin: 10,
      //   filename: filename,
      // });
    },
    print() {
      let cardsToPrint = [];
      if (this.enableAll) {
        const allEligibleCards = this.benefitCards?.filter((c) => c.IsEligible);
        cardsToPrint = allEligibleCards.map(
          (card) => card.Source.split(",")[1]
        );
      } else {
        const card = this.benefitCards?.find((c) => c.Type === this.types);
        cardsToPrint.push(card.Source.split(",")[1]);
      }
      printFileFromBase64(cardsToPrint, "jpg");
    },
    async sendCardByEmail() {
      let cardIds = [];
      if (this.enableAll) {
        this.benefitCards?.forEach((c) => {
          if (c.IsEligible) {
            cardIds.push(c.Id);
          }
        });
      } else {
        const card = this.benefitCards?.find((c) => c.Type === this.types);
        // console.log("sending card", card);
        if (card) cardIds.push(card.Id);
      }
      this.sending = true;
      try {
        await this.sendBenefitCardByEmail({
          planIdentityId: this.user.plan,
          payload: {
            Language: this.$vuetify.lang.current,
            Email: this.user.username,
            FirstName: this.userProfile.FirstName,
            LastName: this.userProfile.LastName,
            BenefitCardIds: cardIds,
          },
        });
        this.snackbar = true;
      } catch (error) {
        console.log(error);
        this.error = {
          customMessage: this.$t(
            "components.vue_material_dashboard.items.benefit_cards.error"
          ),
        };
      } finally {
        this.sending = false;
      }
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    async loadData() {
      try {
        this.loading = true;
        const data = (
          await this.getBenefitCards({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        if (data) {
          this.benefitCards = data.BenefitCards;
          this.checkCardTypes();
        }
        this.loading = false;
      } catch (error) {
        // this.benefitCards = mockBenefitCards.BenefitCards;
        // this.checkCardTypes();
        this.error = error;
        console.log(error);
        this.loading = false;
      }
    },
    checkCardTypes() {
      if (this.benefitCards?.length && this.coverages.length) {
        this.hasCards = true;
        // console.log("has cards checked", this.hasCards);
        this.benefitCards?.forEach((c) => {
          if (
            (c.Name.toLowerCase().includes("health") &&
              c.Name.toLowerCase().includes("dental")) ||
            (c.Name.toLowerCase().includes("santé") &&
              c.Name.toLowerCase().includes("dentaire"))
          ) {
            // The card is health
            if (
              this.coverages.includes("health") &&
              this.coverages.includes("dental")
            ) {
              // Member is eligible for health and dental
              this.hasHealthDentalCard = true;
              c.Type = "health-dental";
              c.IsEligible = true;
              return;
            } else {
              // Member does not have coverage for this card => mark card as not eligible
              c.IsEligible = false;
            }
          }

          if (
            c.Name.toLowerCase().includes("dental") ||
            c.Name.toLowerCase().includes("dentaire")
          ) {
            // The card is dental
            if (this.coverages.includes("dental")) {
              // Member is eligible for health and dental
              this.hasDentalCard = true;
              c.Type = "dental";
              c.IsEligible = true;
              return;
            } else {
              // Member does not have coverage for this card => mark card as not eligible
              c.IsEligible = false;
            }
          }

          if (
            c.Name.toLowerCase().includes("health") ||
            c.Name.toLowerCase().includes("santé")
          ) {
            // The card is health and dental
            if (this.coverages.includes("health")) {
              // Member is eligible for health and dental
              this.hasHealthCard = true;
              c.Type = "health";
              c.IsEligible = true;
              return;
            } else {
              // Member does not have coverage for this card => mark card as not eligible
              c.IsEligible = false;
            }
          }

          if (
            c.Name.toLowerCase().includes("medical") ||
            c.Name.toLowerCase().includes("médicale")
          ) {
            // The card is medical
            if (this.coverages.includes("international")) {
              // Member is eligible for medical card
              this.hasHealthDentalCard = true;
              c.Type = "medical";
              c.IsEligible = true;
              return;
            } else {
              // Member does not have coverage for this card => mark card as not eligible
              c.IsEligible = false;
            }
          }

          if (
            c.Name.toLowerCase().includes("travel") ||
            c.Name.toLowerCase().includes("voyage")
          ) {
            if (this.coverages.includes("travel")) {
              this.hasTravelCard = true;
              c.Type = "travel";
              c.IsEligible = true;
              return;
            } else {
              c.IsEligible = false;
            }
          }

          if (
            c.Name.toLowerCase().includes("drug") ||
            c.Name.toLowerCase().includes("médicament")
          ) {
            if (
              this.coverages.includes("health") ||
              this.coverages.includes("dental")
            ) {
              this.hasDrugCard = true;
              c.Type = "drug";
              c.IsEligible = true;
              return;
            } else {
              c.IsEligible = false;
            }
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.action-btn {
  border: 2px solid;
}
.action-btn-text {
  font-weight: 600;
}

.action-icon {
  font-size: 20px !important;
}
.note {
  font-size: 16px;
  line-height: 1.1;
}
.v-btn > .v-btn__content .v-icon {
  color: var(--info);
  font-size: 40px !important;
}
.all-btn {
  font-size: 14px !important;
  font-weight: 600;
}
.all-button >>> .v-btn__content {
  width: 50px;
  white-space: break-spaces !important;
}
.v-tooltip__content {
  max-width: 120px !important;
  font-size: 10px;
}
.btn-text {
  font-size: 10px;
  font-weight: 700;
  width: 75px;
  white-space: break-spaces;
  word-wrap: normal;
  text-align: center;
}
.bottom-note {
  font-size: 12px;
  font-weight: 700;
}
</style>
