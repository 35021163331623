<template>
  <div>
    <v-snackbar
      v-if="current"
      :style="snackStyle"
      v-model="current.snackbar"
      color="error"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      {{ current.message }}
      <router-link v-if="link" :to="link" class="secondary--text">{{
        linkText
      }}</router-link>
      <v-btn dark text @click="current.snackbar = false">{{
        $t("globals.close")
      }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "error-handler",
  props: ["error", "snackStyle", "time", "link", "linkText"],
  mounted() {
    if (this.time) this.timeout = this.time;
  },
  data() {
    return {
      current: null,
      mode: "",
      timeout: 2500,
    };
  },
  watch: {
    error: {
      handler(v) {
        //console.log("Error: changed: ", v);
        if (v) {
          this.handleError(v);
        }
      },
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    handleError(error) {
      console.log(error);
      let e = {
        snackbar: true,
        id: Math.random(),
      };
      if (error.response) {
        if (error.response.data) {
          console.log("error.response.data", error.response.data);
        }
        //console.log(error.response.status, error.response.data.Code);
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        if (error.response.status === 409) {
          switch (error.response.data.Code) {
            /*
              TODO: Turn messages to keys and add to translations!!! server should be sending only code or short message!
            */
            case 75: {
              e.message = this.$t("components.errorhandler.error409_75");
              break;
            }
            case 100: {
              e.message = this.$t("components.errorhandler.error409_100");
              break;
            }
            case 101: {
              e.message = this.$t("components.errorhandler.error409_101");
              break;
            }
            case 1100: {
              e.message = this.$t(
                "components.errorhandler.error409_1100_{0}",
                error.messageParams
              );
              break;
            }
            case 1200: {
              e.message = this.$t("components.errorhandler.error409_1200");
              break;
            }
            case 200: {
              e.message = this.$t("components.errorhandler.error409_200");
              break;
            }
            case 300: {
              e.message = this.$t("components.errorhandler.error409_300");
              break;
            }
            case 301: {
              e.message = this.$t("components.errorhandler.error409_301");
              break;
            }
            case 400: {
              e.message = this.$t("components.errorhandler.error409_400");
              break;
            }
            case 401: {
              e.message = this.$t("components.errorhandler.error409_401");
              break;
            }
            case 410: {
              e.message = this.$t("components.errorhandler.error409_410");
              break;
            }
            case 600: {
              e.message = this.$t("components.errorhandler.error409_600");
              break;
            }
            case 700: {
              e.message = this.$t("components.errorhandler.error409_700");
              break;
            }
            case 702: {
              e.message = this.$t("components.errorhandler.error409_702");
              break;
            }
            case 703: {
              e.message = this.$t("components.errorhandler.error409_703");
              break;
            }
            case 800: {
              e.message = this.$t("components.errorhandler.error409_800");
              break;
            }
            case 801: {
              e.message = this.$t("components.errorhandler.error409_801");
              break;
            }
            case 900: {
              e.message = this.$t("components.errorhandler.error409_900");
              break;
            }
            case 1300: {
              e.message = this.$t("components.errorhandler.error409_1300");
              break;
            }
            case 1301: {
              e.message = this.$t("components.errorhandler.error409_1301");
              break;
            }
            case 2000: {
              e.message = this.$t("components.errorhandler.error409_2000");
              break;
            }
            case 2001: {
              e.message = this.$t("components.errorhandler.error409_2001");
              break;
            }
            case 2002: {
              e.message = this.$t("components.errorhandler.error409_2002");
              break;
            }
            case 2003: {
              e.message = this.$t("components.errorhandler.error409_2003");
              break;
            }
            case 2100: {
              e.message = this.$t("components.errorhandler.error409_2100");
              break;
            }
            case 2200: {
              e.message = this.$t("components.errorhandler.error409_2200");
              break;
            }
            default: {
              e.message = this.$t("components.errorhandler.error409_default");
            }
          }
        } else if (error.response.status === 503) {
          e.message = this.$t("components.errorhandler.error503");
        } else if (error.response.status === 500) {
          e.message = this.$t("components.errorhandler.error500");
        } else if (error.response.status === 404) {
          e.message = this.$t("components.errorhandler.error404");
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          e.message = this.$t("components.errorhandler.error401");
        } else {
          /*if (error.response.data.message) {
            e.message = error.response.data.message;
          } else {
            e.message = error.toJSON().message;
          }*/
          e.message = this.$t("components.errorhandler.status_error_{0}", [
            error.response.status,
          ]);
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        e.message = this.$t("components.errorhandler.error_no_response");
      } else if (error.clientCode) {
        switch (error.clientCode) {
          case "getReportsMail": {
            e.message = this.$t(
              "components.errorhandler.error_get_reports_mail"
            );
            break;
          }
          case "insuranceCarriersNoPolicy": {
            e.message = this.$t("components.errorhandler.error_no_policy");
            break;
          }
          case "noLockHeaders": {
            e.message = this.$t(
              "components.errorhandler.error_no_lock_headers"
            );
            break;
          }
          default: {
            e.message = this.$t("components.errorhandler.error_client_default");
            break;
          }
        }
      } else if (error.customMessage) {
        e.message = error.customMessage;
      } else {
        e.message = this.$t("components.errorhandler.error_default");
      }

      this.add(e);
    },
    add(error) {
      if (!this.current || !this.current.snackbar) this.current = error;
      else
        setTimeout(
          (err) => {
            this.add(err);
          },
          100,
          error
        );
    },
  },
};
</script>

<style></style>
