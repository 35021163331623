<template>
  <section class="pdf-content">
    <v-row>
      <v-col cols="8">
        <v-row align="center">
          <img
            class="user-image"
            :src="require('@/assets/MASU-logo.png')"
            width="150"
          />
          <!-- <img alt="logo" :src="logoForHtml" width="150px" /> -->

          <div class="ml-5 provider-text">{{ planAdministratorName }}</div>
        </v-row>
        <v-col class="text-left summary-text pt-8" align-self="end">
          {{ $t("components.claim_submission_preview.summary") }}:
          <strong>{{ claim.SubmissionNumber }}</strong>
        </v-col>
      </v-col>
      <v-col cols="3" class="text-left">
        <div style="position: relative">
          <v-col class="pa-0 member-labels">
            {{
              certificateLabel ||
              $t("components.claim_submission_preview.certificate")
            }}:
            <span class="member-values">
              {{ member && member.Certificate }}
            </span>
          </v-col>
          <v-col class="pa-0 member-labels">
            {{ $t("globals.name") }}:
            <span class="member-values">
              {{
                $helpers.capitalize(member?.FirstName + " " + member?.LastName)
              }}
            </span>
          </v-col>
          <v-col class="pa-0 member-labels">
            {{ $t("globals.dob") }}:
            <span class="member-values">
              {{ $helpers.formatDate(member?.Dob) }}
            </span>
          </v-col>
          <v-col class="pa-0 member-labels">
            {{ $t("globals.phone") }}:
            <span class="member-values">
              {{ formatPhone(member?.HomePhone) }}
            </span>
          </v-col>
          <v-col class="pa-0 member-labels">
            {{ $t("globals.email") }}:
            <span class="member-values">
              {{ member?.EmailAddress }}
            </span>
          </v-col>
          <v-col class="pa-0 member-labels">
            {{ $t("globals.address") }}:
            <span class="member-values">
              {{ fetchedAddress }}
            </span>
          </v-col>
        </div>
        <v-col class="pa-0 member-labels">
          {{ $t("components.claim_submission_preview.comments") }}:
          <span class="member-values">
            {{ claim.Comments }}
          </span>
        </v-col>
      </v-col>
    </v-row>
    <div class="hr-divider"></div>

    <section class="pdf-item">
      <!-- <claim-submit-preview :claim="claim" :member="member" /> -->
      <v-row class="report-title" no-gutters> Claim Submission Summary </v-row>
      <v-row class="report-title" no-gutters>
        Submission ID: {{ claim.SubmissionNumber }}
      </v-row>
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import masker from "vue-the-mask/src/masker";
import { tokens } from "vue-the-mask";
// import ClaimSubmitPreview from "@/components/ClaimCentre/ClaimSubmitPreview.vue";
export default {
  props: {
    reportData: Object,
    member: {
      type: Object,
      default: () => {},
    },
    claim: {
      type: Object,
      default: () => {},
    },
  },
  // components: { ClaimSubmitPreview },
  data() {
    return {
      telephoneMask: "(###) ### - ####",
      fetchedAddress: null,
    };
  },

  computed: {
    ...mapGetters("common", [
      "enumModels",
      "identityUser",
      "settings",
      "certificateLabel",
      "planAdministratorName",
      "logo",
    ]),
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
  watch: {
    member: {
      handler(v) {
        if (v) {
          this.internalMember = JSON.parse(JSON.stringify(v));
          this.fetchedAddress = this.$helpers.formatDrawbridgeAddress(
            v.Address
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getColumnNames(data) {
      const names = new Set();
      for (const row of data) {
        for (const key of Object.keys(row)) {
          names.add(key);
        }
      }
      return names;
    },
    formatPhone(v) {
      return masker(v, this.telephoneMask, true, tokens);
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  color: red !important;
}

.primary {
  background-color: red;
}
.pdf-content {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  padding: 40px 60px;
  width: 100%;
  background: #fff;

  .report-body {
    .report-info {
      display: flex;

      padding-bottom: 0px;
      justify-content: space-between;

      .image-container {
        width: 150px;
        height: 75px;
        overflow: hidden;
        position: relative;
        margin-right: 25px;
        margin-bottom: 10px;

        .user-image {
          max-width: 150px;
          height: auto;
        }
      }

      .user-details {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        font-size: 18px;

        .user-name {
          margin-bottom: 10px;
          flex-shrink: 0;
        }

        .detail-container {
          flex-shrink: 0;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .detail-label {
            font-weight: 600;
            margin-right: 10px;
          }
          .detail-text {
            font-size: 13px;
          }
        }
      }
    }

    .hr-divider {
      border-bottom: 1px solid rgb(163, 163, 163);
    }

    .report-title {
      font-size: 18px;
      font-weight: 400;
      margin: 10px 0 5px 0;
    }

    .report-subtitle {
      font-size: 16px;
      font-weight: 400;
      margin: 10px 0;
    }
  }
}
</style>
<style scoped>
.table >>> th {
  background-color: red !important;
  color: white !important;
  border: 1px solid rgb(187, 187, 187);
  height: 50px !important;
}
.table >>> td {
  border: 1px solid rgb(187, 187, 187);
  height: 40px !important;
  text-align: center;
}
.footer-image {
  max-width: 150px;
  height: auto;
  margin-left: 50%;
  transform: translateX(-50%);
}
.report-footer {
  margin-top: 40px;
}
p {
  font-size: 12px;
  margin: 10px 0;
  text-align: justify;
}
.address {
  font-size: 12px;
  text-align: left;
  padding-bottom: 0;
}
.custom-underline {
  text-decoration: none;
  padding-bottom: 0px;
  border-bottom: 1px solid;
  cursor: pointer;
}
</style>
