<template>
  <div>
    <v-form v-model="valid" v-if="member">
      <v-container class="py-4">
        <v-row align-content="center">
          <v-col cols="12" sm="6" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="
                certificateLabel ||
                $t('components.member_validation_form.student_id_certficate')
              "
              :value="member.Certificate"
              type="number"
              class="required"
              :rules="sinRules"
            ></no-autocomplete-textfield>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.firstname')"
              :value="member.FirstName"
              :rules="[$rules.required, $rules.validName]"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.lastname')"
              :value="member.LastName"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0" v-if="member.MiddleName">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.middlename')"
              :value="member.MiddleName"
              :rules="[$rules.required, $rules.validLastName]"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-menu
              v-if="
                optionalMemberFields.filter((o) => o === 'DateOfBirth').length >
                0
              "
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-mask="'####-##-##'"
                  clearable
                  :dense="$vuetify.breakpoint.xsOnly ? true : false"
                  outlined
                  :value="dob"
                  :label="$t('components.member_validation_form.dob')"
                  append-icon="mdi-calendar"
                  @click:append="dateMenu = true"
                  @input="
                    $emit('dob', $event);
                    dob.length > 3 ? (pickerDate = dob) : '';
                  "
                  v-bind="attrs"
                  :rules="[
                    $rules.required,
                    $rules.validDate,
                    $rules.dateOfBirth,
                  ]"
                  class="required"
                ></v-text-field>
              </template>
              <v-date-picker
                class="px-0 mx-0"
                :value="dob"
                :max="$helpers.toISOStringLocal(new Date())"
                @input="
                  dateMenu = false;
                  $emit('dob', $event);
                "
                :picker-date.sync="pickerDate"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-else
              disabled
              outlined
              :value="dob"
              :label="$t('components.member_validation_form.dob')"
              append-icon="mdi-calendar"
              readonly
              :rules="[$rules.required]"
              class="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
              disabled
              outlined
              :value="
                $vuetify.lang.current === 'en'
                  ? enumModels.Gender.filter(
                      (o) => o.value === member.Gender
                    )[0]?.textEn
                  : enumModels.Gender.filter(
                      (o) => o.value === member.Gender
                    )[0]?.textFr
              "
              :label="$t('components.registration_completion_form.gender')"
              :rules="[$rules.required]"
              class="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
              disabled
              outlined
              :value="
                member.MemberGroup.Translations.filter(
                  (o) => o.LanguageCode === $vuetify.lang.current
                )[0].Name
              "
              :label="
                memberGroupLabel ||
                $t('components.registration_completion_form.member_group')
              "
              readonly
              :rules="[$rules.required]"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapGetters } from "vuex";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    member: Object,
    savingImage: Boolean,
    dob: String,
  },
  data() {
    return {
      dateMenu: false,
      pickerDate: "1985-06",
      valid: false,
      showPhotoCrop: false,
      sinRules: [
        this.$rules.required,
        (v) => (v && v.length === 4) || "Must be 4 digits",
      ],
    };
  },
  computed: {
    ...mapGetters("common", [
      "enumModels",
      "optionalMemberFields",
      "certificateLabel",
      "memberGroupLabel",
    ]),
  },
};
</script>
<style scoped>
.camera-btn {
  right: 25px;
  top: 25px;
  position: relative;
}
.avatar {
  border: 3px white solid;
}
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.v-btn__content .v-icon {
  margin-right: 0 !important;
}
</style>
