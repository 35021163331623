import axios from "axios";
import { TokenService } from "./token.service";
import store from "@/store";

const mapiTokenForTestCCWIPP =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijg0NzkwQTdEOUU1MzczMkJBQTc1NjQwMDRBMEIxQkM4IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA3OTY2MTUsImV4cCI6MTY4MDg4MzAxNSwiaXNzIjoiaHR0cHM6Ly9kZXYubWFwaS5wYmFzY29ubmVjdC5jb20iLCJhdWQiOlsicG9ydGFsLXByb3h5IiwibmVvc3BpbiJdLCJjbGllbnRfaWQiOiJkZXZfbXBfY2N3aXBwX3BiYXNjb25uZWN0X2NvbSIsInN1YiI6ImFiZDdmZmJjLWQwZjEtNGMzZi1iNTkzLTdkNDNmYTYzMzhlNCIsImF1dGhfdGltZSI6MTY4MDc5NjYxNCwiaWRwIjoibG9jYWwiLCJkcmF3YnJpZGdlTWVtYmVySWQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJnYXRld2F5TWVtYmVySWQiOiIxNTAwMDMwNTUyIiwibmVvc3Bpbk1lbWJlcklkIjoiNDEzNTQ1IiwicGxhbiI6IjUzYmU2MjI5LTlkYTctNDhjOS1iZTI4LTU5NTViMzI0Mzg1YSIsImdhdGV3YXlQbGFuIjoiNjYyIiwibGFuZ3VhZ2UiOiJlbiIsImlzUmVnaXN0cmF0aW9uQ29tcGxldGVkIjoiVHJ1ZSIsImNvbmZpcm1lZCI6IlRydWUiLCJyb2xlIjoiTWVtYmVyIiwiZW1haWwiOiJhbmRyZW5lb0B0ZXN0LmNvbSIsInVzZXJuYW1lIjoiYW5kcmVuZW9AdGVzdC5jb20iLCJqdGkiOiIyNDJGMzAyQkMyNjQ5RjU3NEVDMERFQkVBOTk1RTAyQiIsInNpZCI6IjcxRTE4MTNFODBDOTczQzY5MjYzRDZENkVEMDQzNjNEIiwiaWF0IjoxNjgwNzk2NjE1LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwibWVtYmVycyIsIm9mZmxpbmVfYWNjZXNzIl0sImFtciI6WyJwd2QiXX0.ripM7PESEMxFY2r31W2ucKUDzxl6uROwN2vgTRhytk0W8aqmJG3jwbRbMz7skyrRik5_BUtARP_PP0Jrgy965esgzpHwVcQrt-1hsZ6Fsc-T-uhOfFW6XZ0zw80b456rYpdqP4aOa4BUF_x7KwiJsRwkTkKF0NRPvH2obtEoBYogkTpiuiOLLuV66d71Cz--gKaZOGUE02dbNSjvm_i-HLXzbDfTLC4_SDX5NZn3WE27IQp9qfllL8WLSiCUnj03QgAQDVt62kzO5231aCWZr7VQ-DSK5RpnWOBQ5l5-lf_3CwRW7Sa2eEKsAZpMX3INmu-h6zskyo5hp3kNsmIsKQ";

const mapiTokenForTestDrawbridge =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijg0NzkwQTdEOUU1MzczMkJBQTc1NjQwMDRBMEIxQkM4IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA3OTY2MTMsImV4cCI6MTY4MDg4MzAxMywiaXNzIjoiaHR0cHM6Ly9kZXYubWFwaS5wYmFzY29ubmVjdC5jb20iLCJhdWQiOlsicG9ydGFsLXByb3h5IiwibmVvc3BpbiJdLCJjbGllbnRfaWQiOiJkZXZfbXBfbWFzdV9wYmFzY29ubmVjdF9jb20iLCJzdWIiOiJhYmQ3ZmZiYy1kMGYxLTRjM2YtYjU5My03ZDQzZmE2MzM4ZTQiLCJhdXRoX3RpbWUiOjE2ODA3OTY2MTEsImlkcCI6ImxvY2FsIiwiZHJhd2JyaWRnZU1lbWJlcklkIjoiNzM4MWJhZmItM2VlNS00MmQ2LTk3ODktZGNmNzRkMjllMmMxIiwiZ2F0ZXdheU1lbWJlcklkIjoiMDYyNTE5ODAwIiwibmVvc3Bpbk1lbWJlcklkIjoiIiwicGxhbiI6IjM0ZGI2MzA4LWM2N2YtNDlhZi1iY2U1LWNhODIxYzA2NDIwNSIsImdhdGV3YXlQbGFuIjoiNjYyIiwibGFuZ3VhZ2UiOiJlbiIsImlzUmVnaXN0cmF0aW9uQ29tcGxldGVkIjoiVHJ1ZSIsImNvbmZpcm1lZCI6IlRydWUiLCJyb2xlIjoiTWVtYmVyIiwiZW1haWwiOiJhbmRyZW5lb0B0ZXN0LmNvbSIsInVzZXJuYW1lIjoiYW5kcmVuZW9AdGVzdC5jb20iLCJqdGkiOiI2MjI1Qjc0NkU2NjhFMEM3NzJCOTQ2MEZGOTdDQzkxRiIsInNpZCI6IjFEMzk1M0ZDOUY4NjI0OTlGMTkyOTdBM0JCNEZCRTYwIiwiaWF0IjoxNjgwNzk2NjEzLCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwibWVtYmVycyIsIm9mZmxpbmVfYWNjZXNzIl0sImFtciI6WyJwd2QiXX0.qySyFzfUOdgVUrIUKNXBqPtCQlpgKW43BYuqTVuhpjy-Krrqt26041nSNpP3nEzpF0314-XjkvG_GGRDLy1BAZQIZyDNN1FbrcUfl5CT1SeyCxcJCLTijNOaa8xT-c7j_SIXyzobfi2TM6dEaBk_1VDOj8rHoGeYBJ41y0vHqBSYO9QHjmx7ksnC8G8QT8ZywQaHpr6fPNvYI82O_U2t65Tah_4LgC5uXqViS3ctQXEH_Sa7TJcR5aVrhP9cZw6Xphj9oXzIdybzgeVIj68Oi4V9awVrWcECYlniwJD2TFid2zWXlSwX6mCSMIcI1qoH9BN910W4S73ggngl15k19w";

var querystring = require("querystring");

const ApiService = {
  _401interceptor: null,
  token: null,

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        /*if (error.request.status == 401) {
                    /*if (error.config.url.includes('/o/token/')) {
                      // Refresh token has failed. Logout the user
                      console.log("API SERVICE: dispatch logout: ", error)
                      store.dispatch('auth/logout')
                      throw error
                    } else {
                      // Refresh the access token
                      console.log("API SERVICE: dispatch refresh: ", error)
                      try {
                        await store.dispatch('auth/refreshToken')
                        // Retry the original request
                        return this.customRequest({
                          method: error.config.method,
                          url: error.config.url,
                          data: error.config.data
                        })
                      } catch (e) {
                        // Refresh has failed - reject the original request
                        throw error
                      }*
                    // Refresh token has failed. Logout the user
                    console.log("API SERVICE: dispatch logout: ", error)
                    store.dispatch('auth/logout')
                } else*/
        throw error;
        // eslint-disable-next-line prettier/prettier
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  },

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.withCredentials = true;
    this.mount401Interceptor();
    //this.setAuthHeader()
  },

  setAuthHeader() {
    //for auth module
    let token = TokenService.getToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
    const currentlanguage = store.getters["common/language"];
    const languages = store.getters["common/languages"];
    const restOfLanguages = languages.filter(
      (l) => l.language !== currentlanguage.language
    );
    if (restOfLanguages?.length) {
      const langStrings =
        currentlanguage.language +
        ", " +
        restOfLanguages.map((l) => l.language).join(", ");
      axios.defaults.headers.common["Accept-Language"] = langStrings;
    } else {
      axios.defaults.headers.common["Accept-Language"] =
        currentlanguage.language;
    }
    // console.log("auth");
  },

  setGuestHeader() {
    axios.defaults.headers.common = {};
    axios.defaults.headers.common["Guest"] = "ShareFiles";
    const currentlanguage = store.getters["common/language"];
    const languages = store.getters["common/languages"];
    const restOfLanguages = languages.filter(
      (l) => l.language !== currentlanguage.language
    );
    if (restOfLanguages?.length) {
      const langStrings =
        currentlanguage.language +
        ", " +
        restOfLanguages.map((l) => l.language).join(", ");
      axios.defaults.headers.common["Accept-Language"] = langStrings;
    } else {
      axios.defaults.headers.common["Accept-Language"] =
        currentlanguage.language;
    }
  },

  setLockHeader() {
    axios.defaults.headers.common = {};
    axios.defaults.headers.common["Lock-State"] = `lock`;
  },
  setUnlockHeader(lockId) {
    axios.defaults.headers.common = {};
    axios.defaults.headers.common["Lock-State"] = `unlock`;
    axios.defaults.headers.common["Lock-Id"] = lockId;
  },

  removeHeaders() {
    axios.defaults.headers.common = {};
    let token = TokenService.getToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  get(resource) {
    this.setAuthHeader();
    return axios.get(resource);
  },

  getCustom(resource) {
    this.setAuthHeader();
    return axios.get(resource, { withCredentials: false });
  },

  getDeployedProxy(resource) {
    const isPensionsPortal = store.getters["common/isPensionsPortal"];
    let token = mapiTokenForTestDrawbridge;
    if (isPensionsPortal) {
      token = mapiTokenForTestCCWIPP;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
    return axios.get(resource);
  },

  getGuest(resource) {
    this.setGuestHeader();
    return axios.get(resource);
  },

  getPublic(resource) {
    console.log("get public: ", JSON.stringify(resource), resource);
    return axios.get(resource);
  },

  post(resource, data) {
    this.setAuthHeader();
    return axios.post(resource, data);
  },

  postDeployedProxy(resource, data) {
    const isPensionsPortal = store.getters["common/isPensionsPortal"];
    let token = mapiTokenForTestDrawbridge;
    if (isPensionsPortal) {
      token = mapiTokenForTestCCWIPP;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
    return axios.post(resource, data);
  },

  postDms(resource, data) {
    this.setAuthHeader();
    return axios.post(resource, data, { withCredentials: false });
  },

  postNoAuth(resource, data) {
    return axios.post(resource, data);
  },

  getNoAuth(resource) {
    axios.defaults.headers.common = {};
    return axios.get(resource, {
      withCredentials: false,
    });
  },

  postGuest(resource, data) {
    this.setGuestHeader();
    console.log(resource, data);
    return axios.post(resource, data);
  },

  postFile(resource, data) {
    this.setAuthHeader();
    return axios.post(resource, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  put(resource, data) {
    this.setAuthHeader();
    return axios.put(resource, data);
  },

  putDeployedProxy(resource, data) {
    const isPensionsPortal = store.getters["common/isPensionsPortal"];
    let token = mapiTokenForTestDrawbridge;
    if (isPensionsPortal) {
      token = mapiTokenForTestCCWIPP;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
    return axios.put(resource, data);
  },

  delete(resource, data) {
    this.setAuthHeader();
    return axios.delete(resource, { data });
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    this.setAuthHeader();
    return axios(data);
  },

  download(url) {
    // console.log(url)
    this.setAuthHeader();
    return axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
    });
  },

  downloadPost(url, filename, payload) {
    // console.log(url)
    this.setAuthHeader();
    return axios({
      url: url,
      method: "POST",
      responseType: "blob", // important
      data: payload,
    });
  },

  getCanadaPost(resource) {
    axios.defaults.headers.common = {};
    return axios.get(resource, {
      withCredentials: false,
    });
  },

  postPaymentsForm(resource, authData, data) {
    axios.defaults.headers.common = {};
    return axios.post(resource, querystring.stringify(data), {
      withCredentials: false,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " + btoa(`${authData.Username}:${authData.Password}`),
      },
    });
  },

  getPayments(resource, token) {
    axios.defaults.headers.common = {};
    return axios.get(resource, {
      withCredentials: false,
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/vnd.fif.api.v1+json",
      },
    });
  },
};

export default ApiService;
