<template>
  <material-card-sba
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    :isBenefitCardsWidget="true"
    color="accent"
    :widgetIcon="widgetIcon"
    :title="$t('components.vue_material_dashboard.items.used_benefits.title')"
  >
    <!-- <v-row>
      <v-col class="note pa-0 pl-4 text-left">{{
        $t("components.vue_material_dashboard.items.used_benefits.note")
      }}</v-col>
    </v-row> -->
    <v-row
      v-if="
        !loading &&
        memberBalances.Patients &&
        memberBalances.Patients.length > 0
      "
      no-gutters
      class="mx-6 mt-0"
      justify="end"
    >
      <v-col cols="12" md="8" align-self="center" class="mt-6">
        <v-row
          no-gutters
          class="mb-0 mb-md-6"
          v-for="i in 2"
          :key="
            selectedPatient &&
            selectedPatient.Balances[i - 1] &&
            selectedPatient.Balances[i - 1].CoverageId
          "
        >
          <v-col
            v-if="selectedPatient && selectedPatient.Balances[i - 1]"
            align-self="center"
            class="text-uppercase benefit-title text-left"
            >{{
              selectedPatient.Balances[i - 1] &&
              selectedPatient.Balances[i - 1].Coverage
            }}</v-col
          >
          <v-col
            class="pa-0 balance-chart"
            v-if="selectedPatient && selectedPatient.Balances[i - 1]"
          >
            <coverage-balance-chart
              :coverageBalance="selectedPatient.Balances[i - 1]"
              :width="120"
              :height="120"
            />
            <v-col
              class="pa-0 amount-title text-uppercase text-left ml-0 ml-md-2"
              >{{
                $t(
                  "components.vue_material_dashboard.items.used_benefits.renewal"
                )
              }}-{{
                selectedPatient.Balances[i - 1] &&
                selectedPatient.Balances[i - 1].RenewalDate
              }}</v-col
            >
            <div class="balances">
              <v-col class="pa-0 amount-title text-uppercase">{{
                $t(
                  "components.vue_material_dashboard.items.used_benefits.allowed"
                )
              }}</v-col>
              <v-col class="pa-0 amount">{{
                $helpers.toCurrency(
                  selectedPatient.Balances[i - 1] &&
                    selectedPatient.Balances[i - 1].Allowed
                )
              }}</v-col>
              <v-col class="pa-0 amount-title text-uppercase">{{
                $t("components.vue_material_dashboard.items.used_benefits.used")
              }}</v-col>
              <v-col class="pa-0 amount">{{
                $helpers.toCurrency(
                  selectedPatient.Balances[i - 1] &&
                    selectedPatient.Balances[i - 1].Used
                )
              }}</v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="4"
        class="pb-4 pb-md-10 align-self-md-center align-self-start"
      >
        <v-list flat class="py-0 py-sm-2">
          <v-list-item-group v-model="selectedItem">
            <v-list-item
              v-for="patient in memberBalances.Patients"
              :key="patient.Id"
              @click="selectedPatient = patient"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action class="mr-2 my-0 my-md-3">
                  <v-checkbox
                    on-icon="mdi-checkbox-blank"
                    :input-value="active"
                    color="accent"
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content class="py-0 py-md-3">
                  <v-list-item-title
                    class="text-left text-uppercase benefit-title"
                    >{{ patient.FirstName }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-row
      v-if="
        !loading &&
        memberBalances.Patients &&
        memberBalances.Patients.length === 0
      "
      no-gutters
      :class="`
      fill-height
      justify-center
      mt-0
      mb-12
      mt-sm-12
      pt-4
      pt-sm-12
      pb-8
      px-4`"
    >
      {{
        $t("components.vue_material_dashboard.items.used_benefits.no_history")
      }}
    </v-row>

    <v-progress-circular
      v-if="loading"
      size="50"
      color="primary"
      class="loader"
      indeterminate
    ></v-progress-circular>
    <v-btn
      color="accent"
      class="visit-btn"
      @click="
        $router.push({
          name: 'benefitsBalance',
          query: { id: selectedPatient.Id },
        })
      "
      >{{
        $t("components.vue_material_dashboard.items.used_benefits.view_all")
      }}</v-btn
    >
  </material-card-sba>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
import CoverageBalanceChart from "@/components/VueMaterialDashboard/material/CoverageBalanceChart.vue";
export default {
  components: {
    MaterialCardSba,
    CoverageBalanceChart,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      memberBalances: { Patients: [] },
      selectedPatient: { Balances: [] },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getBenefitBalances"]),
    async loadData() {
      try {
        this.loading = true;
        this.memberBalances = (
          await this.getBenefitBalances({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loading = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        this.loading = false;
        // this.memberBalances = {
        //   memberIdentityId: "1B974280-7BF7-EC11-AE22-0050569544F1",
        //   Patients: [
        // {
        //   Id: 0,
        //   FirstName: "John",
        //   LastName: "Foster",
        //   Balances: [
        //     {
        //       CoverageId: "dsda",
        //       Coverage: "Chiropractor",
        //       Allowed: 500.0,
        //       Used: 300.0,
        //       RenewalDate: "03/01/2022",
        //     },
        //     {
        //       CoverageId: "1dsada",
        //       Coverage: "Prescription Drugs",
        //       Allowed: 1000.0,
        //       Used: 189.0,
        //       RenewalDate: "12/09/2022",
        //     },
        //     {
        //       CoverageId: "asddssg",
        //       Coverage: "Massage Therapist",
        //       Allowed: 1000.0,
        //       Used: 230.0,
        //       RenewalDate: "22/11/2022",
        //     },
        //   ],
        // },
        // {
        //   Id: 1,
        //   FirstName: "Janette",
        //   LastName: "Foster",
        //   Balances: [
        //     {
        //       CoverageId: "0hgff",
        //       Coverage: "Chiropractor",
        //       Allowed: 500.0,
        //       Used: 230.0,
        //       RenewalDate: "03/01/2022",
        //     },
        //     {
        //       CoverageId: "fdsf0",
        //       Coverage: "Eye Exam",
        //       Allowed: 500.0,
        //       Used: 125.0,
        //       RenewalDate: "16/10/2022",
        //     },
        //   ],
        // },
        // {
        //   Id: 2,
        //   FirstName: "Blain",
        //   LastName: "Foster",
        //   Balances: [
        //     {
        //       CoverageId: "ssdfww0",
        //       Coverage: "Chiropractor",
        //       Allowed: 500.0,
        //       Used: 350.0,
        //       RenewalDate: "03/01/2022",
        //     },
        //     {
        //       CoverageId: "fdsse0",
        //       Coverage: "Acupuncturist",
        //       Allowed: 500.0,
        //       Used: 105.0,
        //       RenewalDate: "15/09/2022",
        //     },
        //   ],
        // },
        // ],
        // };
      } finally {
        if (this.memberBalances && this.memberBalances.Patients.length) {
          const memberBalance = this.memberBalances.Patients.find(
            (p) => p.Id === 0
          );
          const dependantBalances = this.memberBalances.Patients.filter(
            (p) => p.Id !== 0
          ).sort((a, b) =>
            a.FirstName > b.FirstName ? 1 : b.FirstName > a.FirstName ? -1 : 0
          );
          this.memberBalances.Patients = [memberBalance, ...dependantBalances];
          this.selectedItem = 0;
          this.selectedPatient = this.memberBalances.Patients[0];
        } else this.selectedPatient = { Balances: [] };
      }
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 1263px) {
  .benefit-title {
    font-size: 12px;
    font-weight: 700;
  }
  .balances {
    position: absolute;
    top: 25px;
    left: 22px;
  }
}
@media only screen and (min-width: 1264px) {
  .benefit-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
  }
  .balances {
    position: absolute;
    top: 40px;
    left: 33px;
  }
}
.benefit-subtitle {
  font-size: 12px;
  font-weight: 600;
}
.amount-title {
  font-size: 9px;
  font-weight: 700;
}
.amount {
  font-size: 11px;
  font-weight: 700;
}
.offset-36 {
  position: relative;
  top: -36px;
}
.balance-chart {
  position: relative;
}
.visit-btn {
  position: absolute;
  bottom: 24px;
  right: 24px;
}
.v-list-item {
  min-height: 20px;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.note {
  position: absolute;
  font-size: 12px;
  max-width: 400px;
  bottom: 20px;
  left: 0;
}
</style>
