<template>
  <!-- <div id="app">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view />
  </div> -->
  <v-app v-if="!loading" :style="cssVars">
    <right-drawer
      ref="rightDrawer"
      :notifications="notRemovedNotifications"
      :updatingNotifications="updatingNotifications"
      :hasNotifications="hasNotifications"
      :popupNotification="popupNotification"
      @remove-all-notifications="removeAllNotifications"
      @mark-all-as-read="markAllAsRead"
      @mark-as-read="markAsReadNotification($event)"
      @remove-notification="removeNotification($event)"
    />
    <left-drawer ref="leftDrawer" />
    <core-app-bar
      :notifications="notRemovedNotifications"
      :loadingNotifications="loadingNotifications"
      :updatingNotifications="updatingNotifications"
      @toggle-left-nav="$refs.leftDrawer.toggleNav()"
      @toggle-right-nav="$refs.rightDrawer.toggleNav()"
      @open-popup="$refs.leftDrawer.openPopupDialog($event)"
    />
    <core-view />
    <core-footer ref="footer" v-show="isLoggedIn" />
    <error-handler :error="error"></error-handler>
  </v-app>
  <p v-else>Redirecting to login...</p>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ErrorHandler from "./components/ErrorHandler";
import moment from "moment";
export default {
  components: {
    ErrorHandler,

    LeftDrawer: () =>
      import("./components/VueMaterialDashboard/core/LeftDrawer"),
    RightDrawer: () =>
      import("./components/VueMaterialDashboard/core/RightDrawer"),
    CoreFooter: () =>
      import("./components/VueMaterialDashboard/core/AppFooter"),
    CoreAppBar: () => import("./components/VueMaterialDashboard/core/AppBar"),
    CoreView: () => import("./components/VueMaterialDashboard/core/MainView"),
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    ...mapGetters("common", [
      "planAdministrator",
      "logo",
      "settings",
      "vuetifyTheme",
      "favicon",
      "planNotifications",
    ]),

    notRemovedNotifications() {
      return this.notifications.filter((n) => n.Status !== "Removed");
    },

    hasNotifications() {
      return this.sentNotifications?.length > 0;
    },

    cssVars() {
      return {
        "--primary": this.$vuetify.theme.themes.light.primary,
        "--secondary": this.$vuetify.theme.themes.light.secondary,
        "--accent": this.$vuetify.theme.themes.light.accent,
        "--error": this.$vuetify.theme.themes.light.error,
        "--warning": this.$vuetify.theme.themes.light.warning,
        "--info": this.$vuetify.theme.themes.light.info,
        "--success": this.$vuetify.theme.themes.light.success,
      };
    },
  },
  data() {
    return {
      error: null,
      loading: true,
      loadingNotifications: false,
      updatingNotifications: false,
      notifications: [],
      sentNotifications: [],
      popupNotification: null,
    };
  },
  watch: {
    isLoggedIn: {
      async handler(v) {
        if (v) {
          this.setPlan(this.user.plan);
          // await this.loadUserProfileData({
          //   planId: this.user.plan,
          //   userId: this.user.sub,
          // });
          // await this.loadUserImage({ userId: this.user.sub });
          await this.loadNotifications();
        } else {
          this.setPlan(undefined);
        }
      },
    },
    // settings: {
    //   async handler(v) {
    //     console.log("settings:", JSON.stringify(v));
    //     if (v) {
    //       this.init();
    //     }
    //   },
    // },
  },
  methods: {
    // ...mapActions("data", ["getSettings"]),
    ...mapActions("data", ["getNotifications", "updateNotifications"]),
    ...mapActions("common", [
      "setPlan",
      "loadUserProfileData",
      "loadUserImage",
      "loadPortalSettings",
    ]),
    ...mapActions("auth", ["login"]),
    // async init() {
    //   try {
    //     // await this.getSettings();
    //     console.log(
    //       "app init",
    //       this.settings,
    //       JSON.stringify(this.settings),
    //       this.settings.APIURL
    //     );
    //     console.log(Object.keys(this.settings).length);
    //     for (const key of Object.keys(this.settings)) {
    //       console.log(key, this.settings[key]);
    //     }
    //     await this.loadPortalSettings();
    //   } catch (error) {
    //     // Temporary disabled for dev and demo, need uncomment line below to see errors
    //     //this.error = error;
    //   }
    // },
    setVuetifyThemeColorsFromSettings() {
      this.$vuetify.theme.themes.light.primary =
        this.vuetifyTheme.Light.Primary;
      this.$vuetify.theme.themes.light.secondary =
        this.vuetifyTheme.Light.Secondary;
      this.$vuetify.theme.themes.light.accent = this.vuetifyTheme.Light.Accent;
      this.$vuetify.theme.themes.light.error = this.vuetifyTheme.Light.Error;
      this.$vuetify.theme.themes.light.warning =
        this.vuetifyTheme.Light.Warning;
      this.$vuetify.theme.themes.light.info = this.vuetifyTheme.Light.Info;
      this.$vuetify.theme.themes.light.success =
        this.vuetifyTheme.Light.Success;
    },
    setFavicon() {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    markAsReadNotification(notification) {
      // console.log("Mark as read", notification);
      if (
        notification.Status === "Permanent" ||
        notification.Status === "Popup"
      )
        return;
      const notificationIndex = this.notifications.findIndex(
        (n) => n.Id === notification.Id
      );
      this.notifications[notificationIndex].Status = "Read";
      this.notifications[notificationIndex].DateRead = moment().format(
        "YYYY-MM-DD-HH-mm-ss"
      );
      this.updateNotificatoinsForMember();
    },
    removeNotification(notification) {
      console.log("remove notification", notification);
      const notificationIndex = this.notifications.findIndex(
        (n) => n.Id === notification.Id
      );
      this.notifications[notificationIndex].Status = "Removed";
      this.notifications[notificationIndex].DateRead = moment().format(
        "YYYY-MM-DD-HH-mm-ss"
      );
      this.updateNotificatoinsForMember();
    },
    markAllAsRead() {
      console.log("mark all as read");
      let updated = false;
      this.notifications.forEach((n) => {
        if (n.Status !== "Permanent" && n.Status !== "Popup") {
          updated = true;
          n.Status = "Read";
        }
      });
      if (updated) this.updateNotificatoinsForMember();
    },
    removeAllNotifications() {
      console.log("remove all notifications");
      let updated = false;
      this.notifications.forEach((n) => {
        if (n.Status !== "Permanent" && n.Status !== "Popup") {
          updated = true;
          n.Status = "Removed";
        }
      });
      if (updated) this.updateNotificatoinsForMember();
    },
    async updateNotificatoinsForMember() {
      try {
        this.updatingNotifications = true;

        await this.updateNotifications({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: this.notifications,
        });

        this.updatingNotifications = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        this.updatingNotifications = false;
      }
    },
    async loadNotifications() {
      try {
        this.loadingNotifications = true;
        this.sentNotifications = (
          await this.getNotifications({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loadingNotifications = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        // sentNotifications = [
        //   {
        //     Id: "1",
        //     Category: "Profile",
        //     Title:
        //       "ALERT: You have not completed your profile setup - phone number",
        //     Text: "Please go to My Profile page and enter phone number",
        //     Icon: "mdi-account-circle",
        //     Color: "#d12d36",
        //     Status: "New",
        //     DateCreated: "2022-08-04",
        //     DateRead: null,
        //   },
        //   // {
        //   //   Id: "2",
        //   //   Category: "Claim",
        //   //   Title: "NOTICE: You have one new claim status message",
        //   //   Text: "You can view your claim at claims page",
        //   //   Icon: "mdi-currency-usd",
        //   //   Color: "#0091BB",
        //   //   Status: "New",
        //   //   DateCreated: "2022-08-04",
        //   //   DateRead: null,
        //   // },
        //   {
        //     Id: "3",
        //     Category: "Downloads",
        //     Title: "NEW: Annual Statement for 2021 now available in downloads",
        //     Text: "Go to downloads page to view statement",
        //     Icon: "mdi-file-document-outline",
        //     Color: "#F18100",
        //     Status: "New",
        //     DateCreated: "2022-08-04",
        //     DateRead: null,
        //   },
        //   {
        //     Id: "4",
        //     Category: "News",
        //     Title: "NEW: Howto video for claim submissions",
        //     Text: "Follow the link to view video",
        //     Icon: "mdi-help-circle-outline",
        //     Color: "#1E1E1E",
        //     Status: "New",
        //     DateCreated: "2022-08-04",
        //     DateRead: null,
        //   },
        // ];
        this.loadingNotifications = false;
      } finally {
        const popupNotifcations = this.planNotifications.filter(
          (n) => n.Category === "Popup"
        );
        if (popupNotifcations?.length) {
          this.popupNotification = popupNotifcations[0];
        }
        this.sentNotifications.sort((a, b) => a.DateCreated > b.DateCreated);
        this.notifications = [
          ...this.planNotifications,
          ...this.sentNotifications,
        ];
      }
    },
  },
  async mounted() {
    this.$router.afterEach(() => {
      this.loading = false;
      document.title =
        this.planAdministrator + "-" + this.$t("globals.app_title");
    });
    this.setVuetifyThemeColorsFromSettings();
    this.setFavicon();
    // class ThemeBtn extends Block {}
    // ThemeBtn.blotName = "theme-btn";
    // ThemeBtn.className = "theme-btn";
    // ThemeBtn.tagName = "div";
    // console.log("app mounted: ", JSON.stringify(this.settings));
    // if (this.settings) {
    //   this.init();
    // }
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
$font-family: "Montserrat", sans-serif;
html {
  overflow-y: auto !important;
}

// #app {
//   font-family: "Barlow", Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// To switch default font for Montserrat
#app {
  .headline,
  [class*="display-"],
  [class*="text-"] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #dde3e9;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.v-tooltip__content {
  max-width: 400px !important;
  opacity: 1 !important;
  width: 400px !important;
  color: black !important;
  background-color: white !important;
  -webkit-box-shadow: 0 0 10px rgb(146, 146, 146);
  box-shadow: 0 0 10px rgb(146, 146, 146);
}
html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  /* Remove scrollbar space */
  background: #f1f1f1;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: rgb(30, 136, 229);
}
.top-30 {
  padding-top: 30px;
}
.support-exists {
  padding-top: 30px;
  margin-top: 64px !important;
}
.v-btn {
  letter-spacing: 0 !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 7px !important;
}
.v-dialog--scrollable {
  overflow: visible !important;
}
.v-messages {
  min-height: auto !important;
}
table thead th[role="columnheader"] {
  color: rgb(30, 136, 229) !important;
}

/*
  Added so that the horizontal bar that follows the browser's scroll
  gets properly hidden when the stepper step collapses
*/
.v-stepper__wrapper[style^="height: 0"] {
  display: none;
}

.required label:before {
  content: "* ";
  color: red;
  font-weight: 900;
}

.v-btn__content .v-icon {
  font-size: 20px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.v-dialog--scrollable > .v-card > div > .v-card__text {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
}
/********************* Added by Randy James */
/* * {
  font-family: "Barlow", sans-serif !important;
} */
.float-right {
  float: right !important;
}
/* .v-main {
  background-image: url(@/assets/login_background_piggybank.jpg) !important;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
} */

::-webkit-scrollbar-thumb {
  background: #b8b8b8 !important;
}
.v-card__title {
  word-break: keep-all !important;
}
.custom-underline {
  text-decoration: none;
  padding-bottom: 0px;
  border-bottom: 1px solid;
  cursor: pointer;
}
.v-navigation-drawer__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #424242;
  background-color: #424242;
}
.vue-html2pdf .pdf-preview button {
  left: auto !important;
  right: -15px !important;
}
</style>
<style>
.v-card__text {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.auto-search {
  font-size: 10px !important;
  line-height: 1 !important;
}
.auto-search1 {
  font-size: 12px !important;
  line-height: 1 !important;
}
.autocomplete-search > div > div {
  height: 25px !important;
  min-height: 25px !important;
}
.benefits > div > .v-subheader {
  font-weight: 700 !important;
  font-size: 0.825rem;
  color: var(--secondary);
}
.border-right-none {
  border-right: none !important;
}
.border-left-none {
  border-left: none !important;
}
/* Recomenadtions from Randy */
/* .theme--light.v-stepper
  .v-stepper__step:not(.v-stepper__step--active):not(.vstepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background: rgba(0, 0, 0, 0.5) !important;
}

.v-card__subtitle,
.v-card__text {
  font-weight: 700 !important;
}

.v-btn {
  font-weight: 600 !important;
}

.v-card__title {
  font-weight: 600 !important;
} */
</style>
