<template>
  <div>
    <v-dialog
      scrollable
      v-model="confirmBankAccountDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">warning</v-icon>
          {{
            $t(
              "components.direct_deposit_expansion_panel.confirm_bank_account_dialog_title"
            )
          }}
        </v-card-title>
        <v-card-actions>
          <v-btn color text @click="confirmBankAccountDialog = false">
            {{ $t("globals.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="
              confirmBankAccountDialog = false;
              saveMember();
            "
          >
            {{ $t("globals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form
      v-model="valid"
      v-if="internalBankAccount"
      class="pa-2 my-2"
      ref="form"
    >
      <v-row>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <no-autocomplete-textfield
            clearable
            outlined
            v-mask="'###'"
            :label="
              $t('components.direct_deposit_expansion_panel.institution_number')
            "
            v-model="internalBankAccount.InstitutionNumber"
            :rules="[$rules.canadianBankInstitutionNumber, $rules.required]"
            class="required"
          ></no-autocomplete-textfield>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="py-0">
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t('components.direct_deposit_expansion_panel.account_number')
            "
            v-mask="'############'"
            v-model="internalBankAccount.AccountNumber"
            :rules="[$rules.canadianBankAccountNumber, $rules.required]"
            class="required"
          ></no-autocomplete-textfield>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="py-0">
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t('components.direct_deposit_expansion_panel.transit_number')
            "
            v-mask="'#####'"
            v-model="internalBankAccount.TransitNumber"
            :rules="[$rules.canadianBankTransitNumber, $rules.required]"
            class="required"
          ></no-autocomplete-textfield>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="py-0 mt-0 note">
          <v-checkbox
            v-model="internalBankAccount.VerifyDirectDeposit"
            :rules="[$rules.required]"
          >
          </v-checkbox>
        </v-col>
        <v-col class="py-0 mt-0 note required">
          {{ $t("components.direct_deposit_expansion_panel.authorize_text") }}
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapActions } from "vuex";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    saving: Boolean,
    memberBankAccount: Object,
  },
  data() {
    return {
      dateMenu: false,
      internalBankAccount: {},
      valid: false,
      editMode: false,
      errorMessage: "",
      confirmBankAccountDialog: "",
      validating: false,
      authorize: false,
    };
  },
  watch: {
    // bankAccount: {
    //   handler(v) {
    //     this.internalBankAccount = v;
    //   },
    //   deep: true,
    // },
    internalBankAccount: {
      handler(v) {
        this.$emit("update-bank-account", v);
      },
      deep: true,
    },
    valid: {
      handler(v) {
        this.$emit("update-valid", v);
      },
    },
  },
  mounted() {
    if (this.memberBankAccount) {
      this.internalBankAccount = this.memberBankAccount;
      this.internalBankAccount.VerifyDirectDeposit = false;
    }
    this.$refs.form.resetValidation();
  },
  methods: {
    ...mapActions("data", ["validateBankAccount"]),
  },
};
</script>
<style scoped>
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> .v-select__selection {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--selection-controls__ripple {
  margin-right: 0px !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
.note {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
}
.note > .v-input--selection-controls {
  margin-top: 0;
}
</style>
