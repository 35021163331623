<template>
  <material-card-sba-small
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    :widgetIcon="widgetIcon"
    color="accent"
    :title="$t('components.vue_material_dashboard.items.my_recent_claim.title')"
  >
    <v-card height="144" flat to="claims-history">
      <v-row
        no-gutters
        class="fill-height"
        align-content="center"
        v-if="recentClaim.ClaimNo"
      >
        <v-col cols="6" class="pl-2 pl-md-4">
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pa-0 text-left">
              <v-col
                :class="`
                pa-0
                text-subtitle-1
                font-weight-bold
                accent--text
                lh-1`"
                >{{ recentClaim.ClaimNo }}</v-col
              >
              <v-col class="pa-0 text-subtitle-2 font-weight-bold lh-1">{{
                recentClaim.Date
              }}</v-col>
              <v-col
                v-if="recentClaim.PaidTo"
                :class="`
                pa-0
                mb-2
                text-subtitle-2
                font-weight-bold
                text-capitalize
                lh-1`"
                ><span class="text-caption text-uppercase font-weight-medium"
                  >{{
                    $t(
                      "components.vue_material_dashboard.items.my_recent_claim.paid_to"
                    )
                  }}:</span
                ><span class="ml-1">{{
                  formatPaidTo(recentClaim.PaidTo)
                }}</span></v-col
              >
              <v-col
                v-if="recentClaim.ChequeNo"
                class="pa-0 title1 text-uppercase lh-1"
                >{{
                  $t(
                    "components.vue_material_dashboard.items.my_recent_claim.deposit_cheque"
                  )
                }}</v-col
              >
              <v-col
                v-if="recentClaim.ChequeNo"
                class="pa-0 text-subtitle-1 font-weight-bold lh-1"
                >#{{ recentClaim.ChequeNo }}
              </v-col>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" class="text-left">
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pa-0 text-left">
              <v-col class="pa-0 title1 text-uppercase">{{
                $t(
                  "components.vue_material_dashboard.items.my_recent_claim.total_submitted"
                )
              }}</v-col>
              <v-col
                class="pa-0 text-h6 font-weight-bold accent--text lh-1 mb-4"
                >{{ $helpers.toCurrency(recentClaim.TotalSubmitted) }}
              </v-col>

              <v-col class="pa-0 title1 text-uppercase">{{
                $t(
                  "components.vue_material_dashboard.items.my_recent_claim.total_paid"
                )
              }}</v-col>
              <v-col class="pa-0 text-h6 font-weight-bold accent--text lh-1"
                >{{ $helpers.toCurrency(recentClaim.TotalPaid) }}
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="fill-height"
        align-content="center"
        v-if="!recentClaim.ClaimNo && !loading"
      >
        <v-col class="px-6">
          {{
            $t(
              "components.vue_material_dashboard.items.my_recent_claim.no_claims"
            )
          }}
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="fill-height"
        align-content="center"
        v-if="loading"
      >
        <v-col class="px-6">
          <v-progress-circular
            size="50"
            color="accent"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </material-card-sba-small>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCardSbaSmall from "@/components/VueMaterialDashboard/material/MaterialCardSmallSbaDashboard.vue";
// import mockSubmittedClaimsHistoryDrawbridge from "@/components/ClaimCentre/mockSubmittedClaimsHistoryDrawbridge.json";
export default {
  components: {
    MaterialCardSbaSmall,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      memberData: {},
      // mockSubmittedClaimsHistoryDrawbridge:
      //   mockSubmittedClaimsHistoryDrawbridge,
      loading: false,
      recentClaim: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["userProfile", "useLegacyClaims"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getClaimsData", "searchDrawbridgeClaims"]),
    async loadData() {
      try {
        this.loading = true;
        let recentClaims;
        if (this.useLegacyClaims) {
          recentClaims = (
            await this.getClaimsData({
              planIdentityId: this.user.plan,
              memberIdentityId: this.user.sub,
            })
          ).data;
          if (recentClaims.length) {
            this.recentClaim = recentClaims[0];
          }
        } else {
          const data = (
            await this.searchDrawbridgeClaims({
              planIdentityId: this.user.plan,
              memberIdentityId: this.user.sub,
              // memberIdentityId: "8e442e9b-76d4-4706-9f96-79c334234734",
              page: 1,
              itemsPerPage: 2,
              sortBy: "Timestamp",
              sortDesc: true,
            })
          ).data;
          if (data && data.value?.length) {
            const lastClaim = data.value[0];
            this.recentClaim = {
              ClaimNo: lastClaim.Identifier,
              Date: lastClaim.Timestamp?.split("T")[0],
              PaidTo: "",
              ChequeNo: "",
              TotalSubmitted:
                lastClaim.Claims[0]?.CurrentClaimData?.ClaimedAmount,
              TotalPaid: lastClaim.Claims[0]?.CurrentClaimData?.PaidAmount,
            };
          }
        }
      } catch (error) {
        this.error = error;
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    formatPaidTo(paidTo) {
      if (!paidTo) return;
      if (paidTo !== "M" && paidTo !== "P") paidTo = "P";
      return this.$helpers.formatEnumModel(
        paidTo,
        "PaidTo",
        this.$vuetify.lang.current
      );
    },
  },
};
</script>
<style scoped>
.lh-1 {
  line-height: 1.1rem !important;
}
.title1 {
  font-size: 12px;
  font-weight: 600;
}
</style>
