var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.member)?_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"py-4"},[_c('v-row',{attrs:{"align-content":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('no-autocomplete-textfield',{staticClass:"required",attrs:{"disabled":"","clearable":"","outlined":"","label":_vm.certificateLabel ||
              _vm.$t('components.member_validation_form.student_id_certficate'),"value":_vm.member.Certificate,"type":"number","rules":_vm.sinRules}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('no-autocomplete-textfield',{staticClass:"required",attrs:{"disabled":"","clearable":"","outlined":"","label":_vm.$t('components.registration_completion_form.firstname'),"value":_vm.member.FirstName,"rules":[_vm.$rules.required, _vm.$rules.validName]}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('no-autocomplete-textfield',{staticClass:"required",attrs:{"disabled":"","clearable":"","outlined":"","label":_vm.$t('components.registration_completion_form.lastname'),"value":_vm.member.LastName}})],1),(_vm.member.MiddleName)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('no-autocomplete-textfield',{staticClass:"required",attrs:{"disabled":"","clearable":"","outlined":"","label":_vm.$t('components.registration_completion_form.middlename'),"value":_vm.member.MiddleName,"rules":[_vm.$rules.required, _vm.$rules.validLastName]}})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[(
              _vm.optionalMemberFields.filter((o) => o === 'DateOfBirth').length >
              0
            )?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('####-##-##'),expression:"'####-##-##'"}],staticClass:"required",attrs:{"clearable":"","dense":_vm.$vuetify.breakpoint.xsOnly ? true : false,"outlined":"","value":_vm.dob,"label":_vm.$t('components.member_validation_form.dob'),"append-icon":"mdi-calendar","rules":[
                  _vm.$rules.required,
                  _vm.$rules.validDate,
                  _vm.$rules.dateOfBirth,
                ]},on:{"click:append":function($event){_vm.dateMenu = true},"input":function($event){_vm.$emit('dob', $event);
                  _vm.dob.length > 3 ? (_vm.pickerDate = _vm.dob) : '';}}},'v-text-field',attrs,false))]}}],null,false,3300919076),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{staticClass:"px-0 mx-0",attrs:{"value":_vm.dob,"max":_vm.$helpers.toISOStringLocal(new Date()),"picker-date":_vm.pickerDate},on:{"input":function($event){_vm.dateMenu = false;
                _vm.$emit('dob', $event);},"update:pickerDate":function($event){_vm.pickerDate=$event},"update:picker-date":function($event){_vm.pickerDate=$event}}})],1):_c('v-text-field',{staticClass:"required",attrs:{"disabled":"","outlined":"","value":_vm.dob,"label":_vm.$t('components.member_validation_form.dob'),"append-icon":"mdi-calendar","readonly":"","rules":[_vm.$rules.required]}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"disabled":"","outlined":"","value":_vm.$vuetify.lang.current === 'en'
                ? _vm.enumModels.Gender.filter(
                    (o) => o.value === _vm.member.Gender
                  )[0]?.textEn
                : _vm.enumModels.Gender.filter(
                    (o) => o.value === _vm.member.Gender
                  )[0]?.textFr,"label":_vm.$t('components.registration_completion_form.gender'),"rules":[_vm.$rules.required]}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"disabled":"","outlined":"","value":_vm.member.MemberGroup.Translations.filter(
                (o) => o.LanguageCode === _vm.$vuetify.lang.current
              )[0].Name,"label":_vm.memberGroupLabel ||
              _vm.$t('components.registration_completion_form.member_group'),"readonly":"","rules":[_vm.$rules.required]}})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }